import styled from 'styled-components'

export const Wrapper = styled.section`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`
