import React, { useContext } from 'react'

import { PaymentContext } from 'contexts/payment'

import { useTranslation } from 'hooks/useTranslation'
import { useFormatting } from 'hooks/useFormatting'

import * as S from './styles'
import { ProductItem } from './ProductItem'

interface ICheckoutListProps {
  hasRemoveProducts: boolean
}

export const CheckoutList: React.FC<ICheckoutListProps> = ({
  hasRemoveProducts
}) => {
  const { translate } = useTranslation()

  const { formatPrice } = useFormatting()

  const { paymentData } = useContext(PaymentContext)

  return (
    <S.Wrapper>
      <header>
        <h2>{translate('payment.signUp.checkoutSummary.products')}</h2>
      </header>

      <S.Products>
        {paymentData.cart.items.length === 0 && (
          <span>{translate('payment.signUp.checkoutSummary.emptyCart')}</span>
        )}

        <ul>
          {paymentData.cart.items.map((product) => (
            <li key={product.id}>
              <ProductItem {...{ product, hasRemoveProducts }} />
            </li>
          ))}
        </ul>
      </S.Products>

      <S.PriceContainer>
        <strong>
          <sup>Total</sup>
          {formatPrice(paymentData.cart.total)}
          <sub>/Mês</sub>
        </strong>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
