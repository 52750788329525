import React from 'react'

import { Form, Item, Label, Select } from 'components/Form'
import { Button } from 'components/Button'

import { usePageReportType } from './usePageReportType'
import * as S from './styles'

export const ReportType: React.FC = () => {
  const {
    translate,
    renderOrgRelationshipOptions,
    renderTypeOptions,
    reportData,
    onChange,
    onSubmit
  } = usePageReportType()

  return (
    <S.Wrapper>
      <h3>{translate('report.new.reportStep.steps.reportType.title')}</h3>
      <p>{translate('report.new.reportStep.steps.reportType.subtitle')}</p>

      <Form onSubmit={onSubmit}>
        <Item>
          <Label>
            {translate(
              'report.new.reportStep.steps.reportType.form.orgRelationship.label'
            )}
            <Select
              name="orgRelationship"
              value={reportData.orgRelationship}
              onChange={onChange}
              options={renderOrgRelationshipOptions()}
              placeholder={translate(
                'report.new.reportStep.steps.reportType.form.orgRelationship.placeholder'
              )}
              required
            />
          </Label>
        </Item>

        <Item>
          <Label>
            {translate(
              'report.new.reportStep.steps.reportType.form.type.label'
            )}
            <Select
              name="type"
              value={reportData.type}
              onChange={onChange}
              options={renderTypeOptions()}
              placeholder={translate(
                'report.new.reportStep.steps.reportType.form.type.placeholder'
              )}
              required
            />
          </Label>
        </Item>

        <S.ButtonWrapper>
          <Button>
            {translate('report.new.reportStep.steps.reportType.form.button')}
          </Button>
        </S.ButtonWrapper>
      </Form>
    </S.Wrapper>
  )
}
