import styled from 'styled-components'

export const Wrapper = styled.div`
  p {
    text-align: center;
  }
`

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 20rem;
  margin: 2rem auto 0;
`
