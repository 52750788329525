import React from 'react'

import { Container } from 'components/Container'
import { EmailImg } from 'components/EmailImg'
import { useTranslation } from 'hooks/useTranslation'

import * as S from './styles'

export const EmailSent: React.FC = () => {
  const { translate } = useTranslation()

  return (
    <Container>
      <S.ImgWrapper>
        <EmailImg />
      </S.ImgWrapper>
      <p>{translate('emailSent.instruction')}</p>
    </Container>
  )
}
