import React from 'react'

import { Tabs } from 'components/Tabs'
import { ReportLogos } from 'components/_ReportChannel/ReportLogos'
import { ReportType } from 'components/_ReportChannel/_Report/ReportType'
import { ReportDetails } from 'components/_ReportChannel/_Report/ReportDetails'

import { ReportContext } from 'contexts/report'

import { usePageReportStep } from './usePageReportStep'
import * as S from './styles'

export const ReportStep: React.FC = () => {
  const {
    translate,
    tabsRef,
    reportData,
    setReportData,
    isPassedType,
    goToSubtype
  } = usePageReportStep()

  return (
    <ReportContext.Provider value={{ reportData, setReportData, goToSubtype }}>
      <S.Wrapper>
        <ReportLogos />

        <Tabs
          ref={tabsRef}
          render={[
            {
              title: translate(
                'report.new.reportStep.steps.reportType.tabTitle'
              ),
              section: (
                <S.Section>
                  <ReportType />
                </S.Section>
              )
            },
            {
              title: translate(
                'report.new.reportStep.steps.reportDetails.tabTitle'
              ),
              section: (
                <S.Section>
                  <ReportDetails />
                </S.Section>
              ),
              disabled: !isPassedType
            }
          ]}
          pagination
        />
      </S.Wrapper>
    </ReportContext.Provider>
  )
}
