export const login = {
  header: {
    button: 'Criar conta'
  },
  form: {
    title: 'Faça seu login',
    identifier: {
      label: 'E-mail',
      placeholder: 'Digite seu e-mail'
    },
    password: {
      label: 'Senha',
      placeholder: '**************',
      forgot: 'Esqueceu a senha?'
    },
    button: 'Entrar',
    createAccount: {
      span: 'Não tem conta ainda? ',
      link: 'Crie sua conta'
    },
    error: 'Entrada no sistema falhou'
  },
  footer: {
    copyrights: '© 2022 Intuix. Todos os direitos reservados',
    terms: 'Termos e condições',
    privacy: 'Privacidade'
  }
}
