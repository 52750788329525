import styled from 'styled-components'

export const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  padding: 0 0.875rem 0.875rem;

  .form-item {
    padding-bottom: 0;
    padding-left: 0;
  }

  .ui-intuix-button {
    border-radius: 99px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;

    i {
      margin-right: 0;
    }
  }
`
