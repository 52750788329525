import React from 'react'
import { WhatsappLogo, Envelope } from 'phosphor-react'

import { Summary } from '../Summary'

import * as S from './styles'

export const CheckoutStep: React.FC = () => {
  return (
    <S.CheckoutStepContainer>
      <header>
        <h1>Parabéns! Seu cadastro foi realizado 🎉</h1>

        <div>
          <p>
            Nós enviamos as instruções para realizar o primeiro acesso, para o
            e-mail cadastrado.
          </p>
        </div>
      </header>

      <section>
        <Summary />
      </section>

      <footer>
        <div>
          <p>Em caso de dúvidas, entre em contato com o nosso suporte por:</p>
        </div>

        <ul>
          <li>
            <i>
              <WhatsappLogo size={42} />
            </i>
            (11) 96080-7155
          </li>
          <li>
            <i>
              <Envelope size={42} />
            </i>
            contato@intuix.com.br
          </li>
        </ul>
      </footer>
    </S.CheckoutStepContainer>
  )
}
