import React, { useCallback, useRef, useState } from 'react'

import { Tabs, ITabsRef } from 'components/Tabs'
import { Modal, IModalRef } from 'components/Modal'

import { PaymentContext } from 'contexts/payment'

import { usePayment } from 'hooks/usePayment'

import { ProductsStep } from './ProductsStep'
import { SignUpStep } from './SignUpStep'
import { CheckoutStep } from './CheckoutStep'
import { CheckoutList } from './CheckoutStep/CheckoutList'

import * as S from './styles'

export const Payment: React.FC = () => {
  document.title = 'Store - Intuix Tecnologia'

  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false)

  const [isCompletedPurchase, setIsCompletedPurchase] = useState(false)

  const storeTabsRef = useRef<ITabsRef>(null)

  const storeTabsGotoTab = useCallback((nextTab: number) => {
    storeTabsRef.current!.gotoTab(nextTab)
  }, [])

  const checkoutListModalRef = useRef<IModalRef>(null)

  const onOpenCheckoutListModal = () => {
    checkoutListModalRef.current?.onOpen()
  }

  return (
    <PaymentContext.Provider value={usePayment()}>
      <S.Container justifyContent="center">
        <S.Wrapper>
          <Tabs
            ref={storeTabsRef}
            render={[
              {
                title: (
                  <S.Tab className="subtitle-2">
                    <span>1</span>Produtos
                  </S.Tab>
                ),
                section: (
                  <S.Section>
                    <ProductsStep
                      onNext={() => {
                        storeTabsGotoTab(2)

                        setIsRegistrationCompleted(true)
                      }}
                      onOpenCheckoutListModal={onOpenCheckoutListModal}
                    />
                  </S.Section>
                ),
                disabled: isCompletedPurchase
              },
              {
                title: (
                  <S.Tab className="subtitle-2">
                    <span>2</span>Cadastro
                  </S.Tab>
                ),
                section: (
                  <S.Section>
                    <SignUpStep
                      onNext={() => {
                        storeTabsGotoTab(3)

                        setIsCompletedPurchase(true)
                      }}
                      onOpenCheckoutListModal={onOpenCheckoutListModal}
                    />
                  </S.Section>
                ),
                disabled: isCompletedPurchase || !isRegistrationCompleted
              },
              {
                title: (
                  <S.Tab className="subtitle-2">
                    <span>3</span>Acesso
                  </S.Tab>
                ),
                section: (
                  <S.Section>
                    <CheckoutStep />
                  </S.Section>
                ),
                disabled: !isCompletedPurchase
              }
            ]}
          />
        </S.Wrapper>
      </S.Container>

      <Modal ref={checkoutListModalRef}>
        <CheckoutList hasRemoveProducts={true} />
      </Modal>
    </PaymentContext.Provider>
  )
}
