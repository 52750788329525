import { css } from 'styled-components'

export const TypographyStyles = css`
  :root {
    --subtitle-color: var(--gray-300);
    font-size: ${(props) => props.theme.body.fontSize};
  }

  body {
    font-family: ${(props) => props.theme.body.fontFamily};
    font-weight: ${(props) => props.theme.body.fontWeight};
    color: var(--gray-800);
    @media ${(props) => props.theme.breakpoints.mobile} {
      font-size: ${(props) => props.theme.body.mobile.fontSize};
    }
  }

  p,
  .p {
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0;
  }

  h1,
  .h1 {
    font-size: 3rem;
    letter-spacing: -1.5px;
    margin: 2rem 0 1rem;
  }

  h2,
  .h2 {
    font-size: 2rem;
    letter-spacing: -0.5px;
    margin: 1.8rem 0 0.9rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    letter-spacing: 0;
    margin: 1.6rem 0 0.8rem;
  }

  h4,
  .h4 {
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    margin: 1.4rem 0 0.7rem;
  }

  .subtitle-1 {
    font-weight: 400;
    font-size: 1.75rem;
    letter-spacing: 0.15px;
    color: var(--subtitle-color);
  }

  .subtitle-2 {
    font-weight: 400;
    font-size: 1.125rem;
    letter-spacing: 0.15px;
    color: var(--subtitle-color);
  }

  .ui-intuix-button {
    font-size: 1rem;
    letter-spacing: 1.25px;
  }

  .caption {
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .ui-intuix-button {
    font-weight: 700;
  }
`
