import { useParams } from 'react-router-dom'

import { useTranslation } from 'hooks/useTranslation'

export const usePageChatHeader = () => {
  const { translate } = useTranslation()

  const { reportCode } = useParams()

  return { translate, reportCode }
}
