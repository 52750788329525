import React from 'react'

import { usePageChatHeader } from './usePageChatHeader'
import * as S from './styles'

export interface IChatHeader {
  onOpenFinishModal: () => void
}

export const ChatHeader: React.FC<IChatHeader> = ({ onOpenFinishModal }) => {
  const { translate, reportCode } = usePageChatHeader()

  return (
    <S.Wrapper>
      <S.ProtocolAndLink>
        <span>
          {translate('followReport.number')}
          {reportCode}
        </span>
        <S.FinishSession onClick={onOpenFinishModal}>
          {translate('followReport.finishSession.button')}
        </S.FinishSession>
      </S.ProtocolAndLink>
      <S.Notice>
        <p>{translate('followReport.notice')}</p>
      </S.Notice>
    </S.Wrapper>
  )
}
