import { useTranslation } from 'hooks/useTranslation'
import { useState } from 'react'

import { IChatFooter } from '.'

export const usePageChatFooter = ({ addMessage }: IChatFooter) => {
  const { translate } = useTranslation()

  const [messageInput, setMessageInput] = useState<string>('')

  const onChange = (name: string, value: string) => {
    setMessageInput(value)
  }

  const onSubmit = async () => {
    if (!messageInput.length) return

    addMessage(messageInput)
    setMessageInput('')
  }

  return { translate, messageInput, onChange, onSubmit }
}
