export const theme = {
  breakpoints: {
    mobile: '(max-width: 767px)',
    tablet: '(max-width: 1023px)',
    desktop: '(min-width: 1024px)'
  },
  colors: {
    primary: {
      500: '#005A7B',
      400: '#007AA7',
      300: '#00AEEF',
      200: '#2BBCF2',
      100: '#6BD0F6',
      75: '#96DEF8',
      50: '#E6F7FD'
    },

    gray: {
      900: '#1B1B1B',
      800: '#262626',
      700: '#343434',
      600: '#444444',
      500: '#4F4F4F',
      400: '#5D5D5D',
      300: '#696969',
      200: '#767676',
      100: '#848484',
      90: '#929292',
      80: '#9F9F9F',
      70: '#ADADAD',
      60: '#B8B8B8',
      50: '#C6C6C6',
      40: '#E1E1E1',
      30: '#EDEDED',
      20: '#F6F6F6',
      10: '#FAFAFA',
      0: '#FFFFFF'
    },

    green: {
      500: '#2E6E31',
      400: '#357E38',
      300: '#4BB450',
      200: '#6AC16E',
      100: '#97D49A',
      75: '#B5E0B7',
      50: '#EDF8EE'
    },

    yellow: {
      500: '#95610C',
      400: '#AB6F0E',
      300: '#F49F14',
      200: '#F6AF3C',
      100: '#F9C777',
      75: '#FAD89F',
      50: '#FEF5E8'
    },

    red: {
      500: '#7C0000',
      400: '#8F0000',
      300: '#CC0000',
      200: '#D52B2B',
      100: '#E16B6B',
      75: '#EA9696',
      50: '#FAE6E6'
    },

    blue: {
      500: '#004A9C',
      400: '#0055B3',
      300: '#007AFF',
      200: '#2B91FF',
      100: '#6BB2FF',
      75: '#96C8FF',
      50: '#E5F2FF'
    },

    feedback: {
      success: '#3C9040',
      warning: '#F49F14',
      danger: '#CC0000'
    }
  },
  body: {
    fontSize: '100%',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    mobile: {
      fontSize: '87%'
    }
  }
}
