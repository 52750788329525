export const payment = {
  products: {
    heading: 'Selecione os produtos desejados',
    month: '/Mês',
    buy: 'Comprar',
    continue: 'Continuar'
  },
  signUp: {
    heading: 'Dados cadastrais',
    form: {
      company: {
        heading: 'Dados da empresa',
        name: {
          label: 'Nome da empresa',
          placeholder: 'Digite o da empresa'
        },
        legalName: {
          label: 'Razão social',
          placeholder: 'Digite a razão social'
        },
        code: {
          label: 'CNPJ',
          placeholder: '00.000.000/0000-00',
          invalid: 'Este CNPJ não é válido.'
        }
      },
      personal: {
        heading: 'Dados pessoais',
        name: {
          label: 'Nome Completo',
          placeholder: 'Digite seu nome completo'
        },
        email: {
          label: 'E-mail',
          placeholder: 'Digite um e-mail de contato'
        },
        phone: {
          label: 'Telefone',
          placeholder: '(00) 00000-0000',
          invalid: 'Este número não é válido.'
        }
      }
    },
    checkoutSummary: {
      heading: 'Resumo do Pedido',
      companyData: 'Dados cadastrais',
      edit: 'Editar',
      personalData: 'Dados pessoais',
      products: 'Produto(s)',
      shared: 'Compartilhada',
      total: 'Total',
      emptyCart: 'Nenhum produto selecionado'
    },
    button: {
      back: 'Voltar',
      next: 'Próximo'
    }
  },
  checkout: {
    heading: 'Formas de pagamento',
    creditCard: 'Cartão de crédito',
    invoice: {
      text: 'Boleto',
      p1: 'Essa forma de pagamento pode levar até 72 horas para ser identificada.',
      p2: 'A liberação de acesso ao(s) produto(s) será realizada a partir dessa confirmação.'
    },
    creditCardForm: {
      name: {
        label: 'Nome do cartão',
        placeholder: 'Digite o nome igual ao do cartão'
      },
      number: {
        label: 'Número do cartão',
        placeholder: 'Digite o número do cartão',
        invalid: 'Este número de cartão não é válido.'
      },
      expiration: {
        label: 'Data de validade',
        placeholder: '00/0000',
        invalid: 'Esta data de validade não é válida.'
      },
      code: {
        label: 'Código de segurança',
        placeholder: '000',
        invalid: 'Este código não é válido.'
      },
      installments: {
        label: 'Número de parcelas'
      },
      terms: {
        text: 'Declaro que li e aceito os ',
        link: 'termos de uso'
      }
    },
    button: {
      back: 'Voltar',
      next: 'Realizar pagamento'
    }
  }
}
