/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

import { Icons } from 'components/Icons'
import { ChatMessage } from 'components/ChatMessage'
import { InnerHTML } from 'components/InnerHTML'

import { IMessage } from 'interfaces/followReport'

import { useClipboard } from 'hooks/useClipboard'
import { useToast } from 'hooks/useToast'

import { usePageChatMessages } from './usePageChatMessages'

import * as S from './styles'

export interface IChatMessages {
  messages: IMessage[]
}

export const ChatMessages: React.FC<IChatMessages> = ({ messages }) => {
  const { chatRef, getProfilePictureMessageIndexes } = usePageChatMessages({
    messages
  })

  const { copyToClipboard } = useClipboard()

  const { success } = useToast()

  const renderCopyCode = (message: IMessage) => {
    const isCopyMessage = message.content.includes('Copie o código do relato')
    if (!isCopyMessage) return

    const code = message.content.substring(message.content.length - 5)

    return (
      <span
        className="copy-code"
        onClick={() => {
          copyToClipboard(code)

          success('Código do relato copiado!')
        }}
      >
        Copiar código
      </span>
    )
  }

  return (
    <S.Wrapper ref={chatRef}>
      {messages.map((message, index) => {
        return (
          <S.MessageWrapper sender={message.sender} key={index}>
            {getProfilePictureMessageIndexes().includes(index) && (
              <S.ProfilePicture>
                <i>
                  <Icons.User fontSize="1.25rem" color="var(--white)" />
                </i>
              </S.ProfilePicture>
            )}
            <ChatMessage sender={message.sender} className="chat-message">
              <InnerHTML type="span" text={message.content} />
            </ChatMessage>

            {renderCopyCode(message)}
          </S.MessageWrapper>
        )
      })}
    </S.Wrapper>
  )
}
