import React, { useContext } from 'react'
import { PaperPlaneTilt } from 'phosphor-react'

import { Icons } from 'components/Icons'
import { Button } from 'components/Button'

import { useTranslation } from 'hooks/useTranslation'

import { IPaymentStep } from 'interfaces/payment'

import { PaymentContext } from 'contexts/payment'

import { Products } from './Products'

import * as S from './styles'

export const ProductsStep: React.FC<IPaymentStep> = ({
  onNext,
  onOpenCheckoutListModal
}) => {
  const { translate } = useTranslation()

  const { paymentData } = useContext(PaymentContext)

  return (
    <S.ProductsStepContainer>
      <header>
        <div />
        <h2>{translate('payment.products.heading')}</h2>

        <Button onClick={onOpenCheckoutListModal}>
          <Icons.ShoppingCart />
        </Button>
      </header>

      <section>
        <Products />
      </section>

      <footer>
        <Button
          disabled={paymentData.cart.quantity === 0}
          onClick={onNext}
          data-cy="products-step-continue"
        >
          <i>
            <PaperPlaneTilt size={24} />
          </i>
          Ir para Cadastro
        </Button>
      </footer>
    </S.ProductsStepContainer>
  )
}
