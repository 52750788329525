import styled, { css } from 'styled-components'

import { IChatMessage } from '.'

const wrapperModifiers = {
  firstPerson: () => css`
    background-color: var(--primary-300);
    color: var(--white);
  `,

  thirdPerson: () => css`
    background-color: var(--gray-30);
    color: var(--black);
  `
}

export const Wrapper = styled.div<IChatMessage>`
  ${({ sender }) => css`
    max-width: 19rem;
    border-radius: 18px;
    padding: 0.625rem 0.75rem;

    p {
      line-height: 1.2rem;
    }

    ${wrapperModifiers[sender!]}
  `}
`
