import React from 'react'

import { Form, Label, Input } from 'components/Form'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Heading } from 'components/Heading'
import { useTranslation } from 'hooks/useTranslation'
import { useResetPassword } from 'hooks/useResetPassword'

import * as S from './styles'

export const ResetPassword: React.FC = () => {
  const { translate } = useTranslation()
  const { onSubmit, onChange, params, loading, validation } = useResetPassword()

  return (
    <Container>
      <S.Wrapper>
        <Form onSubmit={onSubmit}>
          <Heading>{translate('resetPassword.form.title')}</Heading>
          <p>{translate('resetPassword.form.description')}</p>
          <S.Item>
            <Label>
              {translate('resetPassword.form.passwd.label')}
              <Input
                name="password"
                value={params.password}
                type="password"
                placeholder={translate('resetPassword.form.passwd.placeholder')}
                onChange={onChange}
                disabled={loading}
                required
              />
            </Label>
          </S.Item>
          <S.Item>
            <Label>
              {translate('resetPassword.form.passwdConfirm.label')}
              <Input
                name="confirmPassword"
                value={params.confirmPassword}
                type="password"
                placeholder={translate(
                  'resetPassword.form.passwdConfirm.placeholder'
                )}
                onChange={onChange}
                disabled={loading}
                required
              />
            </Label>
          </S.Item>
          {validation.diffError && (
            <S.Error>
              {translate('resetPassword.form.errors.different')}
            </S.Error>
          )}
          <S.ButtonWrapper>
            <Button background="var(--gray-700)" loading={loading}>
              {translate('resetPassword.form.button')}
            </Button>
          </S.ButtonWrapper>
        </Form>
      </S.Wrapper>
    </Container>
  )
}
