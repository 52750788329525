import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslation } from 'hooks/useTranslation'

export const usePageReportChannel = () => {
  const { translate } = useTranslation()

  const { search } = useLocation()
  const customerId = new URLSearchParams(search).get('customerId')

  const navigate = useNavigate()

  const [code, setCode] = useState<string>('')

  const onChange = (_name: string, value: string) => {
    setCode(value)
  }

  const isDisabledSubmit = useCallback(() => code.length !== 5, [code.length])

  const onSubmit = useCallback(() => {
    if (isDisabledSubmit()) return

    navigate(`/report-channel/follow-report/${code}`)
  }, [code, isDisabledSubmit, navigate])

  return {
    translate,
    navigate,
    code,
    onChange,
    onSubmit,
    customerId
  }
}
