import axios from 'axios'

export const intuixApi = axios.create({
  baseURL: process.env.REACT_APP_INTUIX_API,
  headers: {
    'Cache-Control': 'no-store',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

intuixApi.interceptors.response.use(
  function (response) {
    if (response.config.responseType === 'blob') {
      return Promise.resolve(response.data)
    }
    return Promise.resolve(response.data.data)
  },
  function ({ response }) {
    const { data } = response

    return Promise.reject(data)
  },
)
