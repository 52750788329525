import React from 'react'

import { Button as UIButton, IButton } from '@desenvolvimento-intuix/ui-buttons'

export const Button: React.FC<IButton> = ({
  children,
  background,
  color,
  ...props
}) => {
  return (
    <UIButton
      {...props}
      background={background || 'var(--blue-500)'}
      color={color || 'var(--white)'}
    >
      {children}
    </UIButton>
  )
}
