import * as S from '../styles'

import { Table } from 'components/Table'
import { Col } from 'components/Grid'
import { InnerHTML } from 'components/InnerHTML'

import { useTranslation } from 'hooks/useTranslation'

import stepsImage from 'assets/img/proposal-steps.svg'

import { IProposalData, IProductColumn } from 'interfaces/IProposalData'

interface DefaultProps {
  proposalData: IProposalData
  productColumns: IProductColumn[]
}

export const Default: React.FC<DefaultProps> = ({
  productColumns,
  proposalData,
}: DefaultProps) => {
  const { translate } = useTranslation()

  return (
    <>
      <S.Header>
        <p>{translate('proposal.proposalStep.description1')}</p>

        <img
          src={stepsImage}
          alt={translate('proposal.proposalStep.stepsImageAlt')}
        />

        <p>{translate('proposal.proposalStep.description2')}</p>
      </S.Header>

      <S.Section>
        <S.Scope>
          <h3>{translate('proposal.proposalStep.scope.default.title')}</h3>

          <h4>{translate('proposal.proposalStep.scope.default.validateTime.title')}</h4>
          <p>
            {translate('proposal.proposalStep.scope.default.validateTime.description')}
          </p>

          <h4>{translate('proposal.proposalStep.scope.default.humanSupport.title')}</h4>
          <p>
            {translate('proposal.proposalStep.scope.default.humanSupport.description')}
          </p>

          <h4>
            {translate('proposal.proposalStep.scope.default.reportChannel.title')}
          </h4>
          <p>
            {translate('proposal.proposalStep.scope.default.reportChannel.description')}
          </p>
        </S.Scope>
      </S.Section>

      <S.Section>
        <S.ProposalStakeholders>
          <Col>
            <S.Data>
              <h3>{translate('proposal.proposalStep.client')}</h3>
              <strong>{proposalData?.customer.name}</strong>
              <p>{proposalData?.customer.legalIdentifier}</p>
            </S.Data>

            {proposalData.dependents.length > 0 && (
              <div>
                <h3>{translate('proposal.proposalStep.dependents')}</h3>

                {proposalData.dependents.map((dependent) => (
                  <S.Data key={dependent.legalIdentifier}>
                    <strong>{dependent.name}</strong>
                    <p>{dependent.legalIdentifier}</p>
                  </S.Data>
                ))}
              </div>
            )}
          </Col>
          <Col>
            <S.Data>
              <h3>{translate('proposal.proposalStep.subscriber')}</h3>
              <strong>{proposalData?.subscriber.name}</strong>
              <p>{proposalData?.subscriber.email}</p>
            </S.Data>
          </Col>
        </S.ProposalStakeholders>

        <Table
          columns={productColumns}
          data={proposalData.products}
          filter={false}
          order={false}
          search={false}
        />

        <S.ProductDetails>
          {proposalData.products.map((product) => (
            <S.ProductDetail>
              <h3>{product.name}:</h3>
              <InnerHTML type="rte" text={product.description} />
            </S.ProductDetail>
          ))}
        </S.ProductDetails>


      </S.Section>
    </>
  )
}
