import styled from 'styled-components'

export const ProductsStepContainer = styled.div`
  & > header {
    padding: 0 1rem;

    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: 78rem;
    margin-inline: auto;

    & > h2 {
      margin: 0;
    }

    & > .ui-intuix-button {
      padding: 0;
      height: 42px;
      min-width: 42px;
      width: 42px;
    }
  }

  & > footer {
    display: flex;
    justify-content: center;

    margin-top: 3rem;
  }
`

export const FlexSpacing = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`

export const Cart = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`
