import { ICreateMessageBody, IReportData } from 'interfaces/report'
import { useCallback } from 'react'
import { reportsService } from 'services/reportsService'

export const useRequestReport = () => {
  const parseRequestReport = useCallback((report: IReportData) => {
    return {
      relation: report.orgRelationship!.value,
      reportType: report.type!.value,
      reportSubType: report.subType!.value,
      reportWho: report.toWho!.value,
      customerId: report.customerId
    }
  }, [])

  const createReport = useCallback(
    (report: IReportData) => {
      return reportsService.post('reports/create', parseRequestReport(report))
    },
    [parseRequestReport]
  )

  const listReport = useCallback((code: string) => {
    return reportsService.get(`reports/listOneDetailed/${code}`)
  }, [])

  const listMessages = useCallback((reportId: string) => {
    return reportsService.get(`messages/list?reportId=${reportId}`)
  }, [])

  const createMessage = useCallback((body: ICreateMessageBody) => {
    return reportsService.post('messages/create', body)
  }, [])

  const finishReport = useCallback((id: string) => {
    return reportsService.patch(`reports/finish/${id}`)
  }, [])

  return { createReport, listReport, listMessages, createMessage, finishReport }
}
