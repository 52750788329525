import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequestProposal } from 'hooks/useRequestProposal'
import { useTranslation } from 'hooks/useTranslation'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'hooks/useLoading'
import { useFormatting } from 'hooks/useFormatting'

import {
  initialProposalData,
  IProposalData,
  IProposalData__Dependent,
  IProposalData__Subscriber
} from 'interfaces/IProposalData'

import {
  IResponseProposalDTO,
  IResponseProposalDTO__Product
} from 'dtos/IResponseProposalDTO'

export const usePageProposalStep = () => {
  const { translate } = useTranslation()

  const navigate = useNavigate()

  const { error } = useToast()

  const { loading } = useLoading()

  const { subscriberId } = useParams()

  const { formatPrice } = useFormatting()

  const { listSubscriberProposal } = useRequestProposal()

  const [proposalData, setProposalData] =
    useState<IProposalData>(initialProposalData)

  const productColumns = [
    {
      title: translate('proposal.proposalStep.table.columns.name.title'),
      data: 'name'
    },
    {
      title: translate('proposal.proposalStep.table.columns.value.title'),
      data: 'value'
    },
    {
      title: translate(
        'proposal.proposalStep.table.columns.installments.title'
      ),
      data: 'installments'
    },
    {
      title: 'Valor do produto',
      data: 'productValue'
    },
    {
      title: translate('proposal.proposalStep.table.columns.amount.title'),
      data: 'amount'
    },
    {
      title: translate('proposal.proposalStep.table.columns.totalValue.title'),
      data: 'totalValue'
    }
  ]

  const parseDependents = useCallback(
    (proposalResponse: IResponseProposalDTO) => {
      let dependents: IProposalData__Dependent[] = []

      for (const proposalProduct of proposalResponse.proposalProducts) {
        dependents = [
          ...dependents,
          ...proposalProduct.dependents.map(
            (dependent): IProposalData__Dependent => ({
              name: dependent.fantasyName,
              legalIdentifier: dependent.legalIdentifier
            })
          )
        ]
      }

      dependents = dependents.filter(
        (dependent) =>
          dependent.legalIdentifier !==
          proposalResponse.customer.legalIdentifier
      )

      return dependents
    },
    []
  )

  const parseProposalData = useCallback(
    (proposalResponse: IResponseProposalDTO): IProposalData => {
      return {
        customer: proposalResponse.customer,
        subscriber: proposalResponse.subscriber as IProposalData__Subscriber,
        category: proposalResponse.category,
        startOfContract: proposalResponse.startOfContract,
        endOfContract: proposalResponse.endOfContract,
        products: proposalResponse.proposalProducts.map(
          (proposalProduct: IResponseProposalDTO__Product) => {
            const productValue =
              proposalProduct.value * proposalProduct.installments

            return {
              name: proposalProduct.product.name,
              description: proposalProduct.product.description,
              installments: proposalProduct.installments.toString(),
              value: formatPrice(proposalProduct.value),
              productValue: formatPrice(productValue),
              totalValue: formatPrice(productValue * proposalProduct.amount),
              amount: proposalProduct.amount.toString()
            }
          }
        ),
        dependents: parseDependents(proposalResponse)
      }
    },
    [formatPrice, parseDependents]
  )

  const isSignedProposal = useCallback(
    (proposalResponse: IResponseProposalDTO) => {
      return proposalResponse.subscriber.isSigned
    },
    []
  )

  const isInactivedProposal = useCallback(
    (proposalResponse: IResponseProposalDTO) => {
      const productInactived = proposalResponse.proposalProducts[0].inactivatedAt

      return !!productInactived;
    },
    []
  )

  const onShowProposal = useCallback(() => {
    ;(async () => {
      const loadingId = loading()

      try {
        const proposalResponse = await listSubscriberProposal(subscriberId!)

        if (isSignedProposal(proposalResponse)) {
          navigate('/feed?popup=PROPOSAL_SIGNED')

          loading(loadingId)
        } else if(isInactivedProposal(proposalResponse)){
          navigate('/feed?popup=PROPOSAL_INACTIVATED')

          loading(loadingId)
        }

        setProposalData(parseProposalData(proposalResponse))
      } catch (err) {
        error(translate('proposal.proposalStep.show.error'))
      } finally {
        loading(loadingId)
      }
    })()
  }, [
    error,
    isSignedProposal,
    isInactivedProposal,
    listSubscriberProposal,
    loading,
    navigate,
    parseProposalData,
    subscriberId,
    translate
  ])

  useEffect(() => {
    onShowProposal()
  }, [onShowProposal])

  return { translate, proposalData, productColumns }
}
