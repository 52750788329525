import React from 'react'

import { Button } from 'components/Button'

import { usePageFinishSessionModal } from './usePageFinishSessionModal'
import * as S from './styles'

interface IFinishSessionModal {
  onCloseFinishModal: () => void
  onClickFinishSession: () => void
}

export const FinishSessionModal: React.FC<IFinishSessionModal> = ({
  onCloseFinishModal,
  onClickFinishSession
}) => {
  const { translate } = usePageFinishSessionModal()

  return (
    <S.Wrapper>
      <p className="subtitle-2">
        {translate('followReport.finishSession.modal.confirmation')}
      </p>

      <S.ModalButtons>
        <Button onClick={onClickFinishSession}>
          {translate('followReport.finishSession.modal.finish')}
        </Button>
        <Button onClick={onCloseFinishModal}>
          {translate('followReport.finishSession.modal.stay')}
        </Button>
      </S.ModalButtons>
    </S.Wrapper>
  )
}
