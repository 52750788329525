import { css } from 'styled-components'

export const LoadingStyles = css`
  .loading-container .logo {
    width: 100%;
    max-width: 20rem;
  }

  #root-loading {
    z-index: 999999999999999;
    top: 0;
  }
`
