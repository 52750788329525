import styled from 'styled-components'

export const Wrapper = styled.header``

export const ProtocolAndLink = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 2.25rem;
`

export const FinishSession = styled.span`
  color: var(--blue-200);
  cursor: pointer;
`

export const Notice = styled.div`
  padding: 0.375rem 2.25rem;
  border-bottom: 1px solid var(--gray-50);

  p {
    font-size: 0.875rem;
  }
`
