import { IData } from 'components/Table'

export enum IProposalTypes {
  PROPOSAL_TYPE_ADDITIVE = "PROPOSAL_TYPE_ADDITIVE",
  PROPOSAL_TYPE_DEFAULT = "PROPOSAL_TYPE_DEFAULT"
}

export interface IProductColumn {
  title: string;
  data: string;
}

export interface IProposalData__Customer {
  name: string
  legalIdentifier: string
}

export interface IProposalData__Dependent {
  name: string
  legalIdentifier: string
}

export interface IProposalData__Subscriber {
  name: string
  email: string
  phone: string | null
  isSigned: boolean
}

export interface IProposalData {
  customer: IProposalData__Customer

  subscriber: IProposalData__Subscriber

  dependents: IProposalData__Dependent[]

  products: IData

  category: string

  startOfContract: Date | null

  endOfContract: Date | null
}

export const initialProposalData = {
  customer: { name: '', legalIdentifier: '' },
  subscriber: { name: '', email: '', phone: '', isSigned: false },
  category: 'PROPOSAL_TYPE_DEFAULT',
  startOfContract: null,
  endOfContract: null,
  products: [],
  dependents: []
}
