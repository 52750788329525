import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Form, Input, Item } from 'components/Form'
import { Button } from 'components/Button'
import { InnerHTML } from 'components/InnerHTML'

import { usePageProposalStep } from './usePageProposalStep'

import { ProposalContext } from '../ProposalContext'

import * as S from './styles'
import { IProposalTypes } from 'interfaces/IProposalData'
import { Default } from './Default'
import { Additive } from './Additive'

export const ProposalStep: React.FC = () => {
  let [searchParams] = useSearchParams()

  const { translate, proposalData, productColumns } = usePageProposalStep()

  const { onAcceptProposal, onComplete } = useContext(ProposalContext)

  const proposalCategory = proposalData.category

  return (
    <S.Wrapper>
      <Form
        onSubmit={() => {
          if (window.confirm('Assinar Contrato')) {
            const paramsCode = searchParams.get('code')

            return paramsCode ? onComplete(paramsCode) : onAcceptProposal()
          }
        }}
      >
        {proposalCategory === IProposalTypes.PROPOSAL_TYPE_DEFAULT && (
          <Default {...{ proposalData, productColumns }} />
        )}

        {proposalCategory === IProposalTypes.PROPOSAL_TYPE_ADDITIVE && (
          <Additive {...{ proposalData, productColumns }} />
        )}

        <S.Footer>
          <Item>
            <Input
              label={
                <InnerHTML
                  type="span"
                  text={translate('proposal.proposalStep.footer.terms')}
                />
              }
              type="checkbox"
              required
            />
          </Item>

          <Button type="submit">Assinar Contrato</Button>
        </S.Footer>
      </Form>
    </S.Wrapper>
  )
}
