import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  & > .tabs-container > .tabs-header {
    > div {
      width: min(48rem, 100%);
      margin: 0 auto;

      & h1 {
        text-align: center;
      }
    }
  }

  @media (max-width: 768px) {
    & .tabs-header .tabs-head {
      padding: 0.5rem;
    }
  }
`

export const Tab = styled.div`
  display: flex;
  justify-content: center;

  & > span {
    display: inline-block;
    border-radius: 99px;
    text-align: center;
    width: 1.6rem;
    line-height: 1.6rem;
    font-size: 0.875rem;
    align-self: center;
    color: var(--white);
    margin-right: 0.875rem;
  }
`

export const Section = styled.div`
  padding: 2rem;
  padding-top: 0;
  width: 100%;
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
`
