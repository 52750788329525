import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray-90);
  box-shadow: 0 8px 8px #0000002e;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16rem;
  text-align: center;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;

  & > header {
    img {
      width: 10.75rem;

      margin: 0 auto;
      margin-bottom: 1rem;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 999px;

      margin: 0 auto;

      min-width: 10.75rem;
      width: 10.75rem;
      height: 10.75rem;

      font-size: 7rem;

      background-color: var(--blue-300);
      color: var(--white);

      margin-bottom: 1rem;
    }

    h1 {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
    }
  }

  & > footer {
    button {
      width: 100%;
    }
  }
`

export const Price = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem 0 1rem;

  & > small {
    font-weight: 400;
    font-size: 0.8rem;
  }
`

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.1rem;
  font-size: 1.2rem;

  & > i {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & > span {
    margin: 0 2rem;
    width: 1.2rem;
    text-align: center;
  }
`

export const AddedButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 44px;
  gap: 1rem;

  & > button {
    padding: 0;

    font-size: 1.2rem;
  }

  & > span {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    border: 2px solid var(--green-300);
    color: var(--green-300);

    border-radius: 8px;
  }
`
