import { useCallback } from 'react'

export const useFormatting = () => {
  const formatPrice = useCallback(
    (price: number) =>
      price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
    []
  )

  const parsePriceFloat = useCallback((price: string) => {
    return Number.parseFloat(
      price
        .replaceAll('.', '')
        .replaceAll(',', '.')
        .replace(/[^0-9.-]+/g, '')
    )
  }, [])

  return {
    formatPrice,
    parsePriceFloat
  }
}
