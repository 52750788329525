import React, { useContext } from 'react'

import { ICartItem } from 'interfaces/payment'

import { useTranslation } from 'hooks/useTranslation'
import { useFormatting } from 'hooks/useFormatting'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'

import { PaymentContext } from 'contexts/payment'

import { Price, ProductItemContainer } from './styles'

interface IProductItemProps {
  product: ICartItem
  hasRemoveProducts: boolean
}

export const ProductItem: React.FC<IProductItemProps> = ({
  product,
  hasRemoveProducts
}) => {
  const { clearFromCart } = useContext(PaymentContext)

  const { translate } = useTranslation()

  const { formatPrice } = useFormatting()

  return (
    <ProductItemContainer>
      <div>
        <strong>
          {product.name}
          {product.isShared && (
            <sup>{translate('payment.signUp.checkoutSummary.shared')}</sup>
          )}
        </strong>
      </div>

      <div>
        <Price>
          {formatPrice(+product.value)}
          <sub>/Mês</sub>
        </Price>

        {hasRemoveProducts && (
          <Button
            onClick={() => clearFromCart(product)}
            variant="outline"
            background="var(--red-300)"
          >
            <i>
              <Icons.Trash fontSize={20} />
            </i>
          </Button>
        )}
      </div>
    </ProductItemContainer>
  )
}
