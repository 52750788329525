import * as S from '../styles'

import { useTranslation } from 'hooks/useTranslation'
import { IProductColumn, IProposalData } from 'interfaces/IProposalData'

//import { Table } from 'components/Table'
import { Col } from 'components/Grid'
//import { InnerHTML } from 'components/InnerHTML'

interface AdditiveProps {
  proposalData: IProposalData
  productColumns: IProductColumn[]
}

export const Additive: React.FC<AdditiveProps> = ({
  productColumns,
  proposalData
}) => {
  const { translate } = useTranslation()

  return (
    <>
      <S.Section>
        <S.Scope>
          <h3>{translate('proposal.proposalStep.scope.additive.title')}</h3>

          <br />

          <h4>{translate('proposal.proposalStep.scope.additive.validateTime.title')}</h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.validateTime.description')}
          </p>

          <h4>{translate('proposal.proposalStep.scope.additive.continuousMaintenance.title')}</h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.continuousMaintenance.description')}
          </p>

          <h4>
            {translate('proposal.proposalStep.scope.additive.reportChannel.title')}
          </h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.reportChannel.description')}
          </p>

          <h4>
            {translate('proposal.proposalStep.scope.additive.training.title')}
          </h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.training.description')}
          </p>

          <h4>
            {translate('proposal.proposalStep.scope.additive.climateResearch.title')}
          </h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.climateResearch.description')}
          </p>

          <h4>
            {translate('proposal.proposalStep.scope.additive.addtiveWarning.title')}
          </h4>

          <p>
            {translate('proposal.proposalStep.scope.additive.addtiveWarning.description')}
          </p>
        </S.Scope>
      </S.Section>

      <S.Section>
        <S.ProposalStakeholders>
          <Col>
            <S.Data>
              <h3>{translate('proposal.proposalStep.client')}</h3>
              <strong>{proposalData?.customer.name}</strong>
              <p>{proposalData?.customer.legalIdentifier}</p>
            </S.Data>

            {proposalData.dependents.length > 0 && (
              <div>
                <h3>{translate('proposal.proposalStep.dependents')}</h3>

                {proposalData.dependents.map((dependent) => (
                  <S.Data key={dependent.legalIdentifier}>
                    <strong>{dependent.name}</strong>
                    <p>{dependent.legalIdentifier}</p>
                  </S.Data>
                ))}
              </div>
            )}
          </Col>
          <Col>
            <S.Data>
              <h3>{translate('proposal.proposalStep.subscriber')}</h3>
              <strong>{proposalData?.subscriber.name}</strong>
              <p>{proposalData?.subscriber.email}</p>
            </S.Data>
          </Col>
        </S.ProposalStakeholders>

      </S.Section>
    </>
  )
}
