import styled from 'styled-components'

export const PopupWrapper = styled.div`
  .modal-container {
    width: 100%;
    max-width: 40rem;
  }
`

export const PopupContainer = styled.div`
  width: fit-content;
  margin: 0 auto;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    color: var(--green-500);
    font-size: 5rem;
  }

  p {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;

    display: flex;
    align-items: center;
  }

  button {
    margin-top: 2rem;
  }
`
