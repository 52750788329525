import React, { useContext } from 'react'

import { useTranslation } from 'hooks/useTranslation'

import { PaymentContext } from 'contexts/payment'

import { CheckoutList } from '../CheckoutStep/CheckoutList'

import * as S from './styles'

export const Summary: React.FC = () => {
  const { translate } = useTranslation()

  const { paymentData } = useContext(PaymentContext)

  return (
    <S.Wrapper>
      <header>
        <h2>{translate('payment.signUp.checkoutSummary.heading')}</h2>
      </header>

      <S.Data>
        <div>
          <h3>{translate('payment.signUp.checkoutSummary.companyData')}</h3>
          <ul>
            <li>
              {translate('payment.signUp.form.company.name.label')}:{' '}
              <span>{paymentData.customer.companyName}</span>
            </li>
          </ul>
        </div>

        <div>
          <h3>{translate('payment.signUp.checkoutSummary.personalData')}</h3>
          <ul>
            <li>
              {translate('payment.signUp.form.personal.name.label')}:{' '}
              <span>{paymentData.customer.personalName}</span>
            </li>
            <li>
              {translate('payment.signUp.form.personal.email.label')}:{' '}
              <span>{paymentData.customer.personalEmail}</span>
            </li>
            <li>
              {translate('payment.signUp.form.personal.phone.label')}:{' '}
              <span>{paymentData.customer.personalPhone}</span>
            </li>
          </ul>
        </div>
      </S.Data>

      <CheckoutList hasRemoveProducts={false} />
    </S.Wrapper>
  )
}
