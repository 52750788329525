import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 33.5rem;
  margin: 3.5rem auto 0;
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`
