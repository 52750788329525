import axios from 'axios'

export const reportsService = axios.create({
  baseURL: process.env.REACT_APP_REPORTS_SERVICE,
  headers: {
    'Cache-Control': 'no-store',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

reportsService.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data.data)
  },
  function ({ response }) {
    const { status, data } = response

    if (status === 401) {
      window.location.replace(String(process.env.REACT_APP_WEB_APP))
    }

    return Promise.reject(data)
  }
)
