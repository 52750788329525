import React from 'react'

import { Container as PageContainer } from 'components/Container'

import { Container } from './styles'
import { Link } from 'react-router-dom'

export const NotFound: React.FC = () => {
  return (
    <PageContainer>
      <Container>
        <h1>404</h1>
        <p>Página não encontrada</p>

        <Link to="/login">/login</Link>
      </Container>
    </PageContainer>
  )
}
