import React from 'react'

import { Form, Input, Item } from 'components/Form'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'

import { usePageChatFooter } from './usePageChatFooter'
import * as S from './styles'

export interface IChatFooter {
  addMessage: (content: string) => void
  isLoading: boolean
}

export const ChatFooter: React.FC<IChatFooter> = (props) => {
  const { translate, messageInput, onChange, onSubmit } =
    usePageChatFooter(props)

  return (
    <Form onSubmit={onSubmit}>
      <S.Wrapper>
        <Item>
          <Input
            name="message"
            value={messageInput}
            onChange={onChange}
            placeholder={translate('followReport.message.placeholder')}
          />
        </Item>

        <Button background="var(--primary-300)" disabled={props.isLoading}>
          <i>
            <Icons.Send fontSize="1.4rem" />
          </i>
        </Button>
      </S.Wrapper>
    </Form>
  )
}
