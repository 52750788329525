import React, { useContext } from 'react'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'

import { IProduct } from 'interfaces/payment'

import { PaymentContext } from 'contexts/payment'

import { useTranslation } from 'hooks/useTranslation'
import { useFormatting } from 'hooks/useFormatting'

import * as S from './styles'

export const ProductCard: React.FC<IProduct> = (product) => {
  const { translate } = useTranslation()

  const { formatPrice } = useFormatting()

  const { findInCart, addToCart, removeFromCart } = useContext(PaymentContext)

  const onRemoveFromCart = () => {
    removeFromCart(product)
  }

  const onAddToCart = () => {
    addToCart(product)
  }

  return (
    <S.Wrapper>
      <header>
        {product.logo ? (
          <img src={product.logo} alt="" />
        ) : (
          <i>
            <Icons.Award />
          </i>
        )}

        <h1>{product.name}</h1>
      </header>

      <S.Price>
        {formatPrice(+product.value)}
        <small>{translate('payment.products.month')}</small>
      </S.Price>

      <footer>
        {findInCart(product.id) ? (
          <S.AddedButtonsContainer>
            <span>
              <i>
                <Icons.CheckCircle fontSize={24} />
              </i>
              Adicionado
            </span>

            <Button
              background="var(--red-300)"
              variant="outline"
              title="Remover do carrinho"
              onClick={onRemoveFromCart}
            >
              <Icons.Trash />
            </Button>
          </S.AddedButtonsContainer>
        ) : (
          <Button
            onClick={onAddToCart}
            data-cy={`product-${product.id}`}
            background="var(--green-300)"
          >
            <i>
              <Icons.ShoppingCart />
            </i>
            {translate('payment.products.buy')}
          </Button>
        )}
      </footer>
    </S.Wrapper>
  )
}
