import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Container as PageContainer } from 'components/Container'
import { Modal, IModalRef } from 'components/Modal'
import { Icons } from 'components/Icons'
import { PopupContainer, PopupWrapper } from './styles'
import { Button } from 'components/Button'

interface IPopupContent {
  type: 'SUCCESS' | 'ERROR'
  message: string
  redirectTitle?: string
}

export const Feed: React.FC = () => {
  const popupRef = useRef<IModalRef>(null)

  const location = useLocation()

  const [popupContent, setPopupContent] = useState<IPopupContent>({
    type: 'SUCCESS',
    message: ''
  })

  const [redirectUrl, setRedirectUrl] = useState('')

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const popupType = query.get('popup')
    switch (popupType) {
      case 'DOCUMENT_SIGNED':
        setPopupContent({
          type: 'SUCCESS',
          message: 'Documentos assinados, agora é com a gente!'
        })

        popupRef.current?.onOpen()
        break

      case 'PROPOSAL_SIGNED':
        setPopupContent({
          type: 'SUCCESS',
          message: 'A Proposta foi assinada com sucesso!'
        })

        popupRef.current?.onOpen()
        break

      case 'PROPOSAL_INACTIVATED':
        setPopupContent({
          type: 'ERROR',
          message: 'Esta proposta foi inativada!'
        })

        popupRef.current?.onOpen()
        break
      case 'FINISHED_CHECKLIST':
        setPopupContent({
          type: 'SUCCESS',
          message: 'Checklist finalizado com sucesso!',
          redirectTitle: 'Voltar para certificação'
        })

        const redirectUrl = query.get('redirect')
        setRedirectUrl(redirectUrl || '')

        popupRef.current?.onOpen()
        break
    }
  }, [location.search])

  const onRedirect = () => {
    if (redirectUrl) window.location.replace(redirectUrl)
  }

  return (
    <>
      <PageContainer />

      <PopupWrapper>
        <Modal ref={popupRef} noClickOut noCloseButton>
          <PopupContainer>
            <i>
              {popupContent.type === 'SUCCESS' && <Icons.CheckCircle />}
              {popupContent.type === 'ERROR' && (
                <Icons.AlertTriangle color="var(--red-300)" />
              )}
            </i>

            <p>{popupContent.message}</p>

            {redirectUrl && (
              <Button onClick={onRedirect}>
                {popupContent.redirectTitle || ''}
              </Button>
            )}
          </PopupContainer>
        </Modal>
      </PopupWrapper>
    </>
  )
}
