import { createContext } from 'react'

import { IReportContext } from 'interfaces/report'

export const initialReportData = {
  orgRelationship: null,
  type: null,
  subType: null,
  toWho: null,
  acceptTerms: false,
  customerId: ''
}

export const InitialReportContextData: IReportContext = {
  reportData: initialReportData,
  setReportData: () => {},
  goToSubtype: () => {}
}

export const ReportContext = createContext<IReportContext>(
  InitialReportContextData
)
