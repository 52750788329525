import styled from 'styled-components'

export const Wrapper = styled.div``

export const Tab = styled.p`
  display: flex;
  justify-content: center;
  margin: 0;

  span {
    display: inline-block;
    border-radius: 99px;
    text-align: center;
    width: 1.6rem;
    line-height: 1.6rem;
    font-size: 0.875rem;
    align-self: center;
    background-color: var(--tabs-disabled-color);
    color: var(--white);
    margin-right: 0.875rem;
  }
`

export const Section = styled.div`
  padding: 2.6rem 0;
`
