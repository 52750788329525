import React, { useContext } from 'react'

import { Code } from 'components/Form'
import { Icons } from 'components/Icons'

import { useTranslation } from 'hooks/useTranslation'

import { usePageAuthenticationStep } from './usePageAuthenticationStep'

import { ProposalContext } from '../ProposalContext'

import * as S from './styles'

export const AuthenticationStep: React.FC = () => {
  const { translate } = useTranslation()

  const { code, onChange } = usePageAuthenticationStep()

  const { onComplete } = useContext(ProposalContext)

  return (
    <S.Wrapper>
      <S.Heading>{translate('proposal.authenticationStep.title')}</S.Heading>
      <S.SubTitle>
        {translate('proposal.authenticationStep.subTitle')}
      </S.SubTitle>

      <S.Code>
        <S.Instruction>
          {translate('proposal.authenticationStep.code.title')}
        </S.Instruction>
        <Code
          length={5}
          onComplete={onComplete}
          value={code}
          onChange={onChange}
        />

        <S.ResendCode>
          <i>
            <Icons.RotateCw />
          </i>
          <span>{translate('proposal.authenticationStep.code.resend')}</span>
        </S.ResendCode>
      </S.Code>
    </S.Wrapper>
  )
}
