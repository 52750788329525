import styled from 'styled-components'
import { Item as UIItem } from 'components/Form'

export const SignUpInputsContainer = styled.div`
  input[name='companyName'] {
    text-transform: uppercase;

    &::placeholder {
      text-transform: initial;
    }
  }
`

export const Heading = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2.5rem;
`

export const Item = styled(UIItem)`
  padding: 0;
  margin-top: 1rem;
`

export const Company = styled.div``

export const Personal = styled.div`
  margin-top: 2.5rem;
`

export const Email = styled.div`
  padding-right: 0.5rem;
`

export const Phone = styled.div`
  padding-left: 0.5rem;
`
export const Terms = styled.div`
  font-size: 0.875rem;
  margin-top: 2.5rem;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      flex: none;
      margin-right: 0.6rem;
    }
  }
`
