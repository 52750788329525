import { ISender } from 'interfaces/followReport'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 0.875rem 0.875rem;
  max-height: 34.5rem;
  flex: 1;
  overflow-y: auto;
`

interface IMessageWrapper {
  sender: ISender
}

export const MessageWrapper = styled.div<IMessageWrapper>`
  ${({ sender }) => css`
    position: relative;
    margin-top: 0.5rem;

    ${sender === 'firstPerson' &&
    css`
      display: flex;
      justify-content: flex-end;
    `}

    .chat-message, .copy-code {
      margin-left: 3.2rem;
    }

    .copy-code {
      display: inline-block;
      color: var(--blue-400);
      text-decoration: underline;
      cursor: pointer;

      margin-top: 0.3rem;
    }
  `}
`

export const ProfilePicture = styled.div`
  background-color: var(--primary-300);
  border-radius: 99px;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
`
