import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode'
import { Button } from '@desenvolvimento-intuix/ui-buttons'

import reportChannelSvg from 'assets/img/report-channel.png'
import reportChannelPhonePng from 'assets/img/report-channel-phone.png'

import * as S from './styles'
import { Logo } from 'components/Logo'
import { customerRelationshipService } from 'services/customerRelationshipService'
import { PaperPlaneTilt, Printer } from 'phosphor-react'

export const ReportChannelBanner: React.FC = () => {
  const { customerId } = useParams()

  const [reportChannelUrl, setReportChannelUrl] = useState('')

  const [qrCode, setQrCode] = useState('')

  document.title = 'Canal de Denúncias'

  const query = new URLSearchParams(window.location.search)

  const isAllowToDowload = query.get('download') !== null

  const generateQR = async (text: string) => {
    try {
      return await QRCode.toDataURL(text)
    } catch (err) {
      console.error(err)
    }
  }

  const onGenerateQrCode = useCallback(() => {
    ;(async () => {
      const reportChannelUrl = `${process.env.REACT_APP_WEB_APP}/report-channel?customerId=${customerId}`

      const qrCodeImg = await generateQR(reportChannelUrl)
      if (!qrCodeImg) return

      setQrCode(qrCodeImg)

      setReportChannelUrl(reportChannelUrl)
    })()
  }, [customerId])

  useEffect(() => {
    onGenerateQrCode()
  }, [onGenerateQrCode])

  const [name, setName] = useState<string>()

  const onShowCustomerName = useCallback(() => {
    ;(async () => {
      const response: any = await customerRelationshipService.get(
        `/customers/listCustomerName/${customerId}`
      )

      setName(response.name)
    })()
  }, [customerId])

  useEffect(() => {
    onShowCustomerName()
  }, [onShowCustomerName])

  if (!name || !qrCode) return null

  return (
    <S.ReportChannelBannerContainer>
      <S.PageContainer>
        <S.ReportChannelBannerHeader>
          <S.ReportChannelBannerGrid>
            <S.ReportChannelBannerCol>
              <img src={reportChannelSvg} alt="" />
            </S.ReportChannelBannerCol>
            <S.ReportChannelBannerCol>
              <h1>{name}</h1>
            </S.ReportChannelBannerCol>
          </S.ReportChannelBannerGrid>

          <p>Sua opinião é muito importante para nós!</p>
        </S.ReportChannelBannerHeader>

        <S.ReportChannelBannerSection>
          <p>Nossa solução precisa da sua participação</p>

          <p>
            Se você presenciar ou sofrer algo que prejudique qualquer
            certificação, <strong>Denuncie!</strong>
          </p>
        </S.ReportChannelBannerSection>

        <S.ReportChannelBannerSectionQrCodeSection>
          <p>Envie por meio do QRCode abaixo!</p>

          <S.QRCodeContainer>
            <S.QRCodePhoneImage src={reportChannelPhonePng} alt="" />

            <S.QRCodeImageContainer>
              <img src={qrCode} alt="" />

              <figcaption>Escaneie o QRCode</figcaption>
            </S.QRCodeImageContainer>
          </S.QRCodeContainer>
        </S.ReportChannelBannerSectionQrCodeSection>

        <S.ControlsContainer>
          {isAllowToDowload && (
            <Button
              background="var(--blue-500)"
              color="var(--white)"
              onClick={() => {
                window.print()
              }}
            >
              <i>
                <Printer size={24} />
              </i>
              Imprimir
            </Button>
          )}

          <a href={reportChannelUrl}>
            <Button background="var(--blue-500)" color="var(--white)">
              <i>
                <PaperPlaneTilt size={24} />
              </i>
              Acessar
            </Button>
          </a>
        </S.ControlsContainer>

        <S.ReportChannelBannerFooter>
          <Logo />

          <Logo type="accredited-blockchain" />
        </S.ReportChannelBannerFooter>
      </S.PageContainer>
    </S.ReportChannelBannerContainer>
  )
}
