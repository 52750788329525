import React from 'react'

import { Container } from 'components/Container'
import { Icons } from 'components/Icons'
import { Button } from 'components/Button'
import { Code, Form, Item, Label } from 'components/Form'
import { ReportLogos } from 'components/_ReportChannel/ReportLogos'
import { StatusBarClient } from 'components/_ReportChannel/StatusBarClient'

import { usePageReportChannel } from './usePageReportChannel'
import * as S from './styles'

export const ReportChannel: React.FC = () => {
  const { translate, navigate, code, onChange, onSubmit, customerId } =
    usePageReportChannel()

  return (
    <Container justifyContent="flex-start">
      <S.Wrapper>
        <ReportLogos />

        <StatusBarClient />
        
        <S.ReportArticle>
          <S.CreateReport>
            <i>
              <Icons.Edit2 fontSize="10rem" color="var(--primary-500)" />
            </i>
            <h2>{translate('reportChannel.createReport.title')}</h2>
            <p>{translate('reportChannel.createReport.instructions')}</p>
            <Button
              onClick={() =>
                navigate(`/report-channel/report?customerId=${customerId}`)
              }
            >
              {translate('reportChannel.createReport.button')}
            </Button>
          </S.CreateReport>

          <S.ReportDivider />

          <S.FollowReport>
            <i>
              <Icons.MessageCircle
                fontSize="10rem"
                color="var(--primary-500)"
              />
            </i>
            <h2>{translate('reportChannel.followReport.title')}</h2>
            <p>{translate('reportChannel.followReport.instructions')}</p>

            <Form onSubmit={onSubmit}>
              <Item>
                <Label>
                  {translate('reportChannel.followReport.code.label')}
                  <Code
                    length={5}
                    onComplete={() => {}}
                    value={code}
                    onChange={onChange}
                    placeholder={translate(
                      'reportChannel.followReport.code.placeholder'
                    )}
                    required
                  />
                </Label>
              </Item>
              <Button>
                {translate('reportChannel.followReport.submit.button')}
              </Button>
            </Form>
          </S.FollowReport>
        </S.ReportArticle>
      </S.Wrapper>
    </Container>
  )
}
