import React from 'react'

import { Container } from 'components/Container'
import { Modal } from 'components/Modal'
import { ChatHeader } from 'components/_ReportChannel/_FollowReport/ChatHeader'
import { ChatMessages } from 'components/_ReportChannel/_FollowReport/ChatMessages'
import { ChatFooter } from 'components/_ReportChannel/_FollowReport/ChatFooter'
import { FinishSessionModal } from 'components/_ReportChannel/_FollowReport/FinishSessionModal'
import { FinishedSessionModal } from 'components/_ReportChannel/_FollowReport/FinishedSessionModal'

import { usePageFollowReport } from './usePageFollowReport'
import * as S from './styles'

export const FollowReport: React.FC = () => {
  const {
    messages,
    onAddMessage,
    onOpenFinishModal,
    isLoading,
    finishSessionModal,
    finishedSessionModal,
    onCloseFinishModal,
    onClickFinishSession,
    finishSession
  } = usePageFollowReport()

  return (
    <Container justifyContent="flex-start">
      <S.Wrapper>
        <S.Chat>
          <ChatHeader onOpenFinishModal={onOpenFinishModal} />

          <ChatMessages messages={messages} />

          <ChatFooter addMessage={onAddMessage} isLoading={isLoading} />
        </S.Chat>

        <S.ModalWrapper>
          <Modal ref={finishSessionModal}>
            <FinishSessionModal
              onCloseFinishModal={onCloseFinishModal}
              onClickFinishSession={onClickFinishSession}
            />
          </Modal>
        </S.ModalWrapper>

        <S.ModalWrapper>
          <Modal ref={finishedSessionModal} noClickOut noCloseButton>
            <FinishedSessionModal finishSession={finishSession} />
          </Modal>
        </S.ModalWrapper>
      </S.Wrapper>
    </Container>
  )
}
