import React from 'react'

import { Logo } from 'components/Logo'
import { Link } from 'components/Link'
import { useTranslation } from 'hooks/useTranslation'

import * as S from './styles'

export interface IContainerProps {
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  nav?: JSX.Element | JSX.Element[]
}

export const Container: React.FC<IContainerProps> = ({
  children,
  justifyContent = 'space-between',
  nav = ''
}) => {
  const { translate } = useTranslation()

  return (
    <S.Background>
      <S.Wrapper
        justifyContent={justifyContent}
        className="page-container__wrapper"
      >
        <header>
          <S.LogoWrapper>
            <Logo />
          </S.LogoWrapper>
          {nav && <nav>{nav}</nav>}
        </header>

        <main>{children}</main>

        <footer>
          <span>{translate('login.footer.copyrights')}</span>
          <S.LinksWrapper>
            <Link to="/">{translate('login.footer.terms')}</Link>
            <Link to="/">{translate('login.footer.privacy')}</Link>
          </S.LinksWrapper>
        </footer>
      </S.Wrapper>
    </S.Background>
  )
}
