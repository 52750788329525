import { useCallback, useState } from 'react'
import produce from 'immer'

import { IPaymentData, IProduct } from 'interfaces/payment'

export const usePayment = () => {
  const initPaymentData = useCallback(
    () => ({
      cart: {
        items: [],
        quantity: 0,
        total: 0
      },
      customer: {
        companyName: '',
        personalName: '',
        personalEmail: '',
        personalPhone: ''
      },

      acceptedTerms: false
    }),
    []
  )

  const [paymentData, setPaymentData] = useState<IPaymentData>(
    initPaymentData()
  )

  const findInCart = useCallback(
    (id: string) => {
      return paymentData.cart.items.find((item) => item.id === id)
    },
    [paymentData.cart.items]
  )

  const findIndexInCart = useCallback(
    (id: string) => {
      return paymentData.cart.items.findIndex((item) => item.id === id)
    },
    [paymentData.cart.items]
  )

  const findQuantityInCart = useCallback(
    (id: string) => {
      const index = findIndexInCart(id.toString())
      return index === -1 ? 0 : paymentData.cart.items[index].quantity
    },
    [findIndexInCart, paymentData.cart.items]
  )

  const addToCart = useCallback(
    (product: IProduct) => {
      const nextState = produce(paymentData, (draftState) => {
        if (findInCart(product.id)) {
          const index = findIndexInCart(product.id)
          draftState.cart.items[index].quantity++
        } else {
          draftState.cart.items.push({ ...product, quantity: 1 })
        }

        draftState.cart.quantity++

        draftState.cart.total += +product.value
      })
      setPaymentData(nextState)
    },
    [findInCart, findIndexInCart, paymentData]
  )

  const removeFromCart = useCallback(
    (product: IProduct) => {
      const nextState = produce(paymentData, (draftState) => {
        const index = findIndexInCart(product.id)
        if (draftState.cart.items[index].quantity > 1) {
          draftState.cart.items[index].quantity--
        } else {
          draftState.cart.items.splice(index, 1)
        }

        draftState.cart.quantity--

        draftState.cart.total -= +product.value
      })
      setPaymentData(nextState)
    },
    [findIndexInCart, paymentData]
  )

  const clearFromCart = useCallback(
    (product: IProduct) => {
      const nextState = produce(paymentData, (draftState) => {
        const index = findIndexInCart(product.id)

        const removedQuantity = draftState.cart.items[index].quantity
        draftState.cart.quantity -= removedQuantity

        const removedPrice =
          draftState.cart.items[index].quantity * +product.value
        draftState.cart.total -= removedPrice

        draftState.cart.items.splice(index, 1)
      })
      setPaymentData(nextState)
    },
    [findIndexInCart, paymentData]
  )

  return {
    paymentData,
    setPaymentData,
    findInCart,
    findQuantityInCart,
    addToCart,
    removeFromCart,
    clearFromCart
  }
}
