import styled from 'styled-components'

import reportChannelBackground from 'assets/img/report-channel-background.svg'

import { Col } from 'components/Grid'

export const ReportChannelBannerContainer = styled.div`
  -webkit-print-color-adjust: exact !important;

  background: url(${reportChannelBackground});
  background-repeat: no-repeat;
  background-size: cover;

  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }

    @page {
      margin: 0;
    }
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  margin-top: 3rem;

  a {
    text-decoration: none;
  }

  @media print {
    display: none !important;
  }
`

export const PageContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;

  @media print {
    padding: 0 4rem;
  }
`

export const ReportChannelBannerHeader = styled.header`
  & > p {
    font-size: 1.25rem;
    font-style: italic;
    color: var(--primary-500);
    max-width: 16.25rem;
  }
`

export const ReportChannelBannerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ReportChannelBannerCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin: 2rem 0rem;

  img {
    width: 100%;
    max-width: 22rem;
    object-fit: contain;
  }

  h1 {
    font-size: 2rem;
  }

  & + & {
    border-left: 1px solid var(--gray-60);
  }
`

export const ReportChannelBannerSection = styled.section`
  width: 100%;
  margin-top: 1rem;

  p {
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--primary-500);
    line-height: 2rem;
  }
`

export const ReportChannelBannerSectionQrCodeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;

  p {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-500);
  }
`

export const QRCodeContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  background: var(--white);
`

export const QRCodePhoneImage = styled.img`
  width: 100%;
  max-width: 15rem;
`

export const QRCodeImageContainer = styled.figure`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  img {
    width: 100%;
    max-width: 11rem;
  }

  figcaption {
    font-size: 0.875rem;
    text-align: center;
    font-weight: bold;
  }
`

export const ReportChannelBannerFooter = styled.footer`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem 0;

  svg {
    width: 9rem;
  }
`
