import React, { useCallback, useEffect, useState } from 'react'

import { IProduct } from 'interfaces/payment'

import { useLoading } from 'hooks/useLoading'
import { useRequestProducts } from 'hooks/useRequestProducts'

import { ProductCard } from './ProductCard'

import * as S from './styles'

export const Products: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([])

  const { loading } = useLoading()

  const { showProducts } = useRequestProducts()

  const show = useCallback(async () => {
    const loadingId = loading()

    const productsResponse = await showProducts()

    setProducts(productsResponse)

    loading(loadingId)
  }, [loading, showProducts])

  useEffect(() => {
    show()
  }, [show])

  return (
    <S.Wrapper>
      {products.map((product) => (
        <ProductCard {...product} />
      ))}
    </S.Wrapper>
  )
}
