import styled from 'styled-components'

import { blur } from 'assets/styles/blur'

export const Wrapper = styled.div`
  ${blur}

  max-width: 64rem;
  margin-inline: auto;

  border: 1px solid var(--gray-60);
  border-radius: 4px;
  padding: 2rem;
`

export const Data = styled.div`
  & > div {
    ul {
      list-style: none;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      li {
        font-size: 0.875rem;
        color: var(--gray-100);

        & > span {
          display: block;
          margin-top: 0.125rem;

          font-size: 1.2rem;
          color: var(--gray-800);
        }

        & + li {
          margin-top: 1rem;
        }
      }
    }
  }
`
