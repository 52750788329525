import { useCallback } from 'react'

export const useValidations = () => {
  const validateCnpj = useCallback((cnpj: string): boolean => {
    if (cnpj.length !== 18) {
      return false
    }

    return true
  }, [])

  const validatePhone = useCallback((phone: string): boolean => {
    if (phone.length < 14) {
      return false
    }

    return true
  }, [])

  const validateCardNumber = useCallback((number: string): boolean => {
    if (number.length < 16) {
      return false
    }

    return true
  }, [])

  const validateCardDate = useCallback((date: string): boolean => {
    if (date.length !== 7) {
      return false
    }

    const dateObj = new Date()
    const dateArray = date.split('/')
    const month = parseInt(dateArray[0])
    const year = parseInt(dateArray[1])

    if (year < dateObj.getFullYear()) {
      return false
    } else if (month < dateObj.getMonth()) {
      return false
    }

    return true
  }, [])

  const validateCardCode = useCallback((code: string): boolean => {
    if (code.length !== 3) {
      return false
    }

    return true
  }, [])

  return {
    validateCnpj,
    validatePhone,
    validateCardNumber,
    validateCardDate,
    validateCardCode
  }
}
