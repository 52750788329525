import React from 'react'

import { Icons } from 'components/Icons'
import { Button } from 'components/Button'

import { usePageFinishedSessionModal } from './usePageFinishedSessionModal'
import * as S from './styles'

interface IFinishedSessionModal {
  finishSession: () => void
}

export const FinishedSessionModal: React.FC<IFinishedSessionModal> = ({
  finishSession
}) => {
  const { translate } = usePageFinishedSessionModal()

  return (
    <S.Wrapper>
      <i>
        <Icons.ThumbsUp fontSize="7rem" color="var(--green-500)" />
      </i>

      <h3>{translate('followReport.finishedSession.modal.title')}</h3>

      <p>{translate('followReport.finishedSession.modal.paragraph')}</p>

      <S.ButtonWrapper>
        <Button onClick={finishSession}>
          {translate('followReport.finishedSession.modal.button')}
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  )
}
