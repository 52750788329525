import { useCallback, useContext } from 'react'

import { reportOptions } from 'config/report'

import { IOption } from 'components/Form'

import { ReportContext } from 'contexts/report'

import { useTranslation } from 'hooks/useTranslation'

export const usePageReportType = () => {
  const { translate } = useTranslation()

  const { reportData, setReportData, goToSubtype } = useContext(ReportContext)

  const renderOrgRelationshipOptions = useCallback((): IOption[] => {
    return reportOptions.orgRelationship.map((option) => ({
      label: translate(
        `report.new.reportStep.steps.reportType.form.orgRelationship.options.${option}`
      ),
      value: option
    }))
  }, [translate])

  const renderTypeOptions = useCallback((): IOption[] => {
    let options = []

    for (let type in reportOptions.type) {
      options.push({
        label: translate(
          `report.new.reportStep.steps.reportType.form.type.options.${type}`
        ),
        value: type
      })
    }

    return options
  }, [translate])

  const onChange = (name: string, value: IOption) => {
    setReportData({ ...reportData, [name]: value, subType: null })
  }

  const isDisabledSubmit = useCallback(
    () => !reportData.orgRelationship || !reportData.type,
    [reportData.orgRelationship, reportData.type]
  )

  const onSubmit = () => {
    if (isDisabledSubmit()) return

    goToSubtype()
  }

  return {
    translate,
    renderOrgRelationshipOptions,
    renderTypeOptions,
    reportData,
    onChange,
    onSubmit
  }
}
