import { useCallback, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { IInputOnChange } from 'components/Form'

import { authService } from 'services/authService'

import { useToast } from 'hooks/useToast'
import { useTranslation } from 'hooks/useTranslation'

import { IResetPassword } from 'interfaces/authentication'

export const useResetPassword = () => {
  const initParams = useCallback(
    () => ({
      password: '',
      confirmPassword: ''
    }),
    []
  )

  const { error, success } = useToast()

  const { translate } = useTranslation()

  const navigate = useNavigate()

  const location = useLocation()

  const [params, setParams] = useState<IResetPassword>(initParams())

  const [loading, setLoading] = useState<boolean>(false)

  const [diffError, setDiffError] = useState<boolean>(false)

  const onSubmit = async () => {
    setDiffError(false)
    setLoading(true)

    if (params.password !== params.confirmPassword) {
      setDiffError(true)
      setLoading(false)
      return false
    }

    const urlParams = new URLSearchParams(location.search)
    const token = urlParams.get('token')

    try {
      await authService.patch('/auth/resetPassword', {
        token,
        password: params.password
      })

      setLoading(false)

      navigate('/login')

      success(translate('resetPassword.form.success'))
    } catch (e) {
      error(translate('resetPassword.form.errors.default'))

      setLoading(false)
    }
  }

  const onChange: IInputOnChange = (name, value) => {
    setParams({
      ...params,
      [name]: value
    })
  }

  return {
    onSubmit,
    onChange,
    params,
    loading,
    validation: {
      diffError
    }
  }
}
