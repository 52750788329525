import React from 'react'

import { Container } from 'components/Container'
import { ReportStep } from 'components/_ReportChannel/_Report/_Steps/ReportStep'

import * as S from './styles'

export const Report: React.FC = () => {
  return (
    <Container justifyContent="flex-start">
      <S.Wrapper>
        <S.Section>
          <ReportStep />
        </S.Section>
      </S.Wrapper>
    </Container>
  )
}
