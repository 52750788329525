import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as ptBR from "lang/pt-BR";

i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translation: {
        ...ptBR,
      },
    },
  },
  lng: localStorage.getItem("lang") || "pt-BR",
  fallbackLng: "pt-BR",
  interpolation: {
    escapeValue: false,
  },
});
