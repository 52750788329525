export const forgotPassword = {
  form: {
    title: 'Esqueceu a senha?',
    description:
      'Não se preocupe! Entre com o email associado a sua conta e nós enviaremos um email com as instruções para resetar sua senha.',
    email: {
      label: 'E-mail',
      placeholder: 'Digite seu e-mail'
    },
    button: 'Enviar instruções',
    error: 'Enviar instruções para criar nova senha falhou',
    success:
      'Por favor siga as instruções que nós enviamos para sua caixa de entrada para que consiga criar uma nova senha.'
  }
}
