import styled, { css } from 'styled-components'

export const StatusBar = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;

    i {
      margin-right: 0.5rem;
    }

    .statusActive {
      display: flex;
      justify-content: center;
      background: #2dd36f;
      color: black;
      width: 100%;
      padding: 0.5rem 50%;
      border: none;
      border-radius: 10px;
      font-weight: bold;
    }

    .statusDisable {
      display: flex;
      background: #d3352d;
      color: white;
      padding: 0.5rem 50%;
      width: 100%;
      border: none;
      border-radius: 10px;
      font-weight: bold;
    }
  `}
`

export const LogosCertificates = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
    }

    li {
      margin: 0 10px;
    }

    img {
      width: 5rem;
      margin-bottom: 1rem;
    }

    img:nth-child(2) {
      filter: blur(3px);
    }

    p {
      position: absolute;
      z-index: 2;
      background: #d3352d;
      color: white;
      font-size: 10px;
      padding: 4px 10px;
      margin-top: 1.4rem;
    }

    @media ${theme.breakpoints.mobile} {
      flex-direction: column;
      ul {
        flex-wrap: wrap;
      }
      img {
        width: 4.5rem;
      }
      p {
        padding: 3px 6px;
      }
    }
  `}
`
