import React, { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Container as PageContainer } from 'components/Container'
import { ITabsRef, Tabs } from 'components/Tabs'

import { ProposalStep } from './ProposalStep'
import { AuthenticationStep } from './AuthenticationStep'

import { useTranslation } from 'hooks/useTranslation'

import { ProposalContextProvider } from './ProposalContext'

import * as S from './styles'

export const Proposal: React.FC = () => {
  const { translate } = useTranslation()

  let [searchParams] = useSearchParams()

  const paramsCode = searchParams.get('code')

  const [isPassedProposal, setIsPassedProposal] = useState<boolean>(false)

  const proposalTabsRef = useRef<ITabsRef>(null)

  return (
    <PageContainer justifyContent="center">
      <ProposalContextProvider
        {...{ proposalTabsRef, isPassedProposal, setIsPassedProposal }}
      >
        <S.Container>
          <Tabs
            ref={proposalTabsRef}
            render={
              paramsCode
                ? [
                    {
                      title: (
                        <S.Tab>{translate('proposal.proposalStep.tab')}</S.Tab>
                      ),
                      section: (
                        <S.Section>
                          <ProposalStep />
                        </S.Section>
                      )
                    }
                  ]
                : [
                    {
                      title: (
                        <S.Tab>
                          <span>1</span>
                          {translate('proposal.proposalStep.tab')}
                        </S.Tab>
                      ),
                      section: (
                        <S.Section>
                          <ProposalStep />
                        </S.Section>
                      )
                    },
                    {
                      title: (
                        <S.Tab>
                          <span>2</span>
                          {translate('proposal.authenticationStep.tab')}
                        </S.Tab>
                      ),
                      section: (
                        <S.Section>
                          <AuthenticationStep />
                        </S.Section>
                      ),
                      disabled: !isPassedProposal
                    }
                  ]
            }
          />
        </S.Container>
      </ProposalContextProvider>
    </PageContainer>
  )
}
