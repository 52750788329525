import styled from 'styled-components'

export const CheckoutStepContainer = styled.div`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;

  padding-inline: 1rem;

  & > header {
    h1 {
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  & > section {
    margin-bottom: 3rem;
  }

  & > footer {
    text-align: center;

    ul {
      display: block;
      max-width: 15rem;
      margin: 3rem auto 4rem;

      list-style: none;

      li {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        font-size: 1.5rem;

        line-height: 0;
      }

      li + li {
        margin-top: 1.5rem;
      }
    }
  }

  & > header,
  & > footer {
    & > div {
      max-width: 64rem;
      margin-inline: auto;

      p {
        font-size: 1.5rem;
        line-height: 160%;

        margin-bottom: 1rem;

        color: var(--gray-400);
      }
    }
  }
`
