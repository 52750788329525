import styled from 'styled-components'

export const Wrapper = styled.div`
  & > header {
    h2 {
      margin-bottom: 1.5rem;
    }
  }
`

export const Products = styled.div`
  ul {
    list-style: none;

    li {
      &:nth-child(even) {
        background: var(--gray-30);
      }

      &:nth-child(odd) {
        background: var(--gray-20);
      }
    }
  }
`

export const Count = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.1rem;
  font-size: 0.75rem;

  & > i {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & > span {
    margin: 0 1rem;
    width: 1rem;
    text-align: center;
  }
`

export const TrashIcon = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  padding-left: 0.1rem;
  cursor: pointer;
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 1rem;

  strong {
    font-size: 1.5rem;

    sup {
      margin-right: 0.2rem;
      color: var(--gray-300);
      font-size: 0.875rem;
    }
  }
`
