import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IInputOnChange } from 'components/Form'

import { useToast } from 'hooks/useToast'
import { useTranslation } from 'hooks/useTranslation'

import { IForgotPassword } from 'interfaces/authentication'

import { authService } from 'services/authService'

export const useForgotPassword = () => {
  const initParams = useCallback(
    () => ({
      email: ''
    }),
    []
  )

  const [params, setParams] = useState<IForgotPassword>(initParams())

  const [loading, setLoading] = useState<boolean>(false)

  const { error, success } = useToast()

  const { translate } = useTranslation()

  const navigate = useNavigate()

  const onSubmit = async () => {
    setLoading(true)
    try {
      await authService.post('/auth/forgottenPassword', params)

      setLoading(false)

      navigate('/login')

      success(translate('forgotPassword.form.success'))
    } catch (e) {
      error(translate('forgotPassword.form.error'))
      setLoading(false)
    }
  }

  const onChange: IInputOnChange = (name, value) => {
    setParams({
      ...params,
      [name]: value
    })
  }

  return {
    onSubmit,
    onChange,
    params,
    loading
  }
}
