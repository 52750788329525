import { useCallback, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ITabsRef } from 'components/Tabs'

import { initialReportData } from 'contexts/report'

import { useTranslation } from 'hooks/useTranslation'

import { IReportData } from 'interfaces/report'

export const usePageReportStep = () => {
  const { translate } = useTranslation()

  const { search } = useLocation()
  const customerId = new URLSearchParams(search).get('customerId')

  const [reportData, setReportData] = useState<IReportData>({
    ...initialReportData,
    customerId: customerId!
  })

  const [isPassedType, setIsPassedType] = useState<boolean>(false)

  const tabsRef = useRef<ITabsRef>(null)

  const goToTab = useCallback((nextTab: number) => {
    tabsRef.current!.gotoTab(nextTab)
  }, [])

  const goToSubtype = useCallback(() => {
    setIsPassedType(true)
    goToTab(2)
  }, [goToTab])

  return {
    translate,
    tabsRef,
    reportData,
    setReportData,
    isPassedType,
    goToSubtype
  }
}
