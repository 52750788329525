import React, { HTMLAttributes } from 'react'

import { ISender } from 'interfaces/followReport'

import * as S from './styles'

export interface IChatMessage extends HTMLAttributes<HTMLDivElement> {
  sender?: ISender
}

export const ChatMessage: React.FC<IChatMessage> = ({
  sender = 'firstPerson',
  children,
  ...props
}) => {
  return (
    <S.Wrapper sender={sender} {...props}>
      <p>{children}</p>
    </S.Wrapper>
  )
}
