import React from 'react'
import WebFont from 'webfontloader'
import { ThemeProvider } from 'styled-components'
import { Root as RootToast } from '@desenvolvimento-intuix/ui-toast'
import { Root as RootLoading } from '@desenvolvimento-intuix/ui-loading'

import { GlobalStyle } from 'assets/styles/global'
import { theme } from 'assets/styles/theme'

import { Routes } from 'routes'

import 'config/i18n'

WebFont.load({
  google: {
    families: ['Montserrat:300,400,500,700']
  }
})

export const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <RootLoading />
        <RootToast />

        <Routes />
      </ThemeProvider>
    </>
  )
}
