import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 58rem;
  margin: 0 auto;
  padding: 0 1rem;
`

export const Section = styled.div`
  padding-bottom: 2rem;
`
