import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useLoading } from 'hooks/useLoading'
import { useToast } from 'hooks/useToast'
import { useTranslation } from 'hooks/useTranslation'
import { useRequestCustomerRelationshipModule } from 'hooks/intuixApi/useRequestCustomerRelationshipModule'

interface CustomerStatusProps {
  id?: string
  name: string
  systemAccessId: string
  products: string
}

interface Product {
  id: string
  logo: string
  name: string
  hasProduct: boolean
}

export const usePageStatusBarClient = () => {
  const [params] = useSearchParams()

  const navigate = useNavigate()

  const [customerState, setCustomerState] = useState<CustomerStatusProps>({
    systemAccessId: '',
    products: '',
    name: ''
  });

  const { translate } = useTranslation()

  const { error } = useToast()

  const { loading } = useLoading()

  const { findCustomerByIdPublic } = useRequestCustomerRelationshipModule()

  const onShowCustomerStatus = useCallback(() => {
    ;(async () => {
      const loadingId = loading()

      try {
        const customerId = params.get('customerId')

        const customerStatusResponse: CustomerStatusProps =
          await findCustomerByIdPublic({ customerId: customerId! })

        setCustomerState({
          systemAccessId: customerStatusResponse.systemAccessId,
          products: customerStatusResponse.products,
          name: customerStatusResponse.name
        });
      } catch (err) {
        error(translate('reportChannel.error'))

        navigate('/')
      } finally {
        loading(loadingId)
      }
    })()
  }, [error, loading, navigate, params, findCustomerByIdPublic, translate])

  useEffect(() => {
    onShowCustomerStatus()
  }, [onShowCustomerStatus])

  const clientProducts: Product[] = Array.isArray(customerState.products)
    ? customerState.products
    : []

  const contractedProducts = clientProducts.filter(
    (product: Product) => product.hasProduct
  )
  const notContractedProducts = clientProducts.filter(
    (product: Product) => !product.hasProduct
  )

  return {
    translate,
    customerState,
    contractedProducts,
    notContractedProducts
  }
}
