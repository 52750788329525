import React from 'react'

import * as S from './styles'

interface LinkProps {
  to: string
  color?: '--blue-400' | '--black'
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  color = '--blue-400'
}) => {
  return (
    <S.RouterLink to={to} color={color}>
      {children}
    </S.RouterLink>
  )
}
