import React, { useEffect } from 'react'

import { Container } from 'components/Container'

import { useEmailConfirmation } from 'hooks/useEmailConfirmation'

export const EmailConfirmation: React.FC = () => {
  const { getPasswordToken } = useEmailConfirmation()

  useEffect(() => {
    getPasswordToken()
  }, [getPasswordToken])

  return <Container></Container>
}
