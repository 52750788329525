export const proposal = {
  proposalStep: {
    tab: 'Proposta',
    header: {
      title: 'Proposta Comercial',
      presentationButton: 'Acesse nossa apresentação'
    },
    show: {
      error: 'Não foi possível mostrar a proposta.'
    },
    client: 'Cliente',
    dependents: 'Dependentes',
    description1:
      'A Intuix cumpre em seu processo com o cliente, em todas as certificações, os seguintes passos:',
    description2:
      'Para auxiliar o processo, a Intuix disponibiliza o acesso ao sistema e uma pessoa para realizar as todas etapas da certificação juntamente com o cliente.',
    stepsImageAlt: 'Imagem descrevendo cada passo da proposta',
    scope: {
      additive: {
        title: 'Aditivo Contratual',
        validateTime: {
          title: 'Validade do aditivo contratual:',
          description: '3 dias.'
        },
        continuousMaintenance: {
          title: 'Manutenção Contínua:',
          description:
            'Atualização da certificação e todos os seus requisitos mensalmente ou sempre que necessário, garantindo as melhores práticas em conformidade.'
        },
        reportChannel: {
          title: 'Canal de Ouvidoria e Denúncia:',
          description:
            'A plataforma INTUIX tem um canal de Ouvidoria e Denúncia online para todos os clientes disponibilizar para seus stakeholders, com captação de registros em tempo real e com dashboard de gestão e ações, a fim de otimizar a comunicação e os processos internos da organização.'
        },
        training: {
          title: 'Treinamentos:',
          description:
            'Disponibilização de treinamentos agendados e contínuos para colaboradores, mantendo os requisitos e melhores práticas das certificações em prática com toda equipe.'
        },
        climateResearch: {
          title: 'Pesquisa de Clima:',
          description:
            'Captação eficiente da percepção dos colaboradores em relação a aspectos gerais da sua experiência com a organização, aperfeiçoando a gestão.'
        },
        addtiveWarning: {
          title: 'Aditivo Contratual:',
          description:
            'Esse aditivo segue as normativas do contrato assinado anteriormente com a Intuix, com mais 12 meses de vigência a partir do seu aceite'
        }
      },
      default: {
        title: 'Escopo',
        validateTime: {
          title: 'Validade da prosposta:',
          description: '3 dias.'
        },
        humanSupport: {
          title: 'Atendimento Humanizado:',
          description:
            'Disponibilização de um Gestor de Contas para realizar o atendimento durante todo o processo de certificação e posteriormente para manter as atualizações das melhores práticas da certificação.'
        },
        reportChannel: {
          title: 'Canal de Ouvidoria e Denúncia:',
          description:
            'A plataforma INTUIX tem um canal de Ouvidoria e Denúncia online para todos os clientes disponibilizar para seus stakeholders, com captação de registros em tempo real e com dashboard de gestão e ações, a fim de otimizar a comunicação e os processos internos da organização.'
        }
      }
    },
    table: {
      columns: {
        name: {
          title: 'Produto'
        },
        value: {
          title: 'Valor da parcela'
        },
        amount: {
          title: 'Quantidade'
        },
        installments: {
          title: 'Parcelas'
        },
        totalValue: {
          title: 'Valor total'
        }
      }
    },
    subscriber: 'Assinante',
    footer: {
      terms:
        'Declaro que li e aceito os <a href="https://develop-storage-email.s3.sa-east-1.amazonaws.com/TERMO-DE-ADESAO-INTUIX-TECNOLOGIA.pdf" target="_blank" rel="noreferrer noopener">Termos de Adesão</a> e a <a href="https://intuix.com.br/politica-de-privacidade/" target="_blank" rel="noreferrer noopener">Política de Privacidade</a>.',
      acceptButton: 'Contratar agora'
    },
    sendCode: {
      error:
        'Não foi possível confirmar a contratação. Por favor, tente novamente.'
    }
  },
  authenticationStep: {
    tab: 'Autenticação',
    title: 'Autenticação',
    subTitle: 'Confira o código enviado em seu email',
    code: {
      title: 'Entre com o código',
      loading: 'Carregando...',
      resend: 'Reenviar código'
    },
    authenticate: {
      error:
        'Não foi possível concluir a autenticação. Por favor, tente novamente.'
    }
  }
}
