import React from 'react'

export const EmailImg: React.FC = () => {
  return (
    <svg
      width="167"
      height="151"
      viewBox="0 0 167 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1024_1281)">
        <path
          d="M165.379 58.2017C165.319 58.2018 165.261 58.1834 165.212 58.1492L84.4243 1.65783C84.1784 1.48672 83.8863 1.39527 83.5871 1.39578C83.2879 1.39629 82.9961 1.48874 82.7508 1.66069L2.54467 58.1486C2.48123 58.1933 2.40271 58.2108 2.32639 58.1974C2.25007 58.1839 2.18219 58.1406 2.13769 58.0769C2.0932 58.0132 2.07572 57.9343 2.08912 57.8577C2.10251 57.7811 2.14568 57.7129 2.20912 57.6682L82.4154 1.18035C82.7588 0.939652 83.1673 0.810264 83.5862 0.809573C84.005 0.808882 84.4139 0.93692 84.7581 1.17648L165.546 57.6677C165.597 57.7034 165.636 57.7545 165.656 57.8136C165.676 57.8727 165.677 57.9367 165.658 57.9963C165.639 58.0559 165.602 58.1079 165.552 58.1449C165.502 58.1818 165.441 58.2017 165.379 58.2017L165.379 58.2017Z"
          fill="#3F3D56"
        />
        <path
          d="M7.12769 60.2041L83.6664 3.24956L160.786 64.2567L87.61 107.812L47.8818 98.7199L7.12769 60.2041Z"
          fill="#E6E6E6"
        />
        <path
          d="M51.2353 134.715H13.4234C13.1912 134.715 12.9612 134.669 12.7466 134.58C12.532 134.491 12.337 134.361 12.1728 134.196C12.0085 134.031 11.8782 133.836 11.7892 133.62C11.7003 133.405 11.6545 133.174 11.6545 132.941C11.6545 132.708 11.7003 132.477 11.7892 132.262C11.8782 132.046 12.0085 131.851 12.1728 131.686C12.337 131.521 12.532 131.391 12.7466 131.302C12.9612 131.213 13.1912 131.167 13.4234 131.167H51.2353C51.4675 131.167 51.6975 131.213 51.9121 131.302C52.1267 131.391 52.3217 131.521 52.486 131.686C52.6502 131.851 52.7806 132.046 52.8695 132.262C52.9584 132.477 53.0042 132.708 53.0042 132.941C53.0042 133.174 52.9584 133.405 52.8695 133.62C52.7806 133.836 52.6502 134.031 52.486 134.196C52.3217 134.361 52.1267 134.491 51.9121 134.58C51.6975 134.669 51.4675 134.715 51.2353 134.715Z"
          fill="#007AFF"
        />
        <path
          d="M26.9895 127.089H13.4234C13.1912 127.089 12.9612 127.043 12.7466 126.954C12.532 126.865 12.337 126.735 12.1728 126.57C12.0085 126.405 11.8782 126.21 11.7892 125.994C11.7003 125.779 11.6545 125.548 11.6545 125.315C11.6545 125.082 11.7003 124.851 11.7892 124.636C11.8782 124.42 12.0085 124.225 12.1728 124.06C12.337 123.895 12.532 123.765 12.7466 123.676C12.9612 123.587 13.1912 123.541 13.4234 123.541H26.9895C27.2216 123.541 27.4516 123.587 27.6662 123.676C27.8808 123.765 28.0758 123.895 28.2401 124.06C28.4043 124.225 28.5347 124.42 28.6236 124.636C28.7125 124.851 28.7583 125.082 28.7583 125.315C28.7583 125.548 28.7125 125.779 28.6236 125.994C28.5347 126.21 28.4043 126.405 28.2401 126.57C28.0758 126.735 27.8808 126.865 27.6662 126.954C27.4516 127.043 27.2216 127.089 26.9895 127.089Z"
          fill="#007AFF"
        />
        <path
          d="M84.7428 91.9271C84.4554 91.9274 84.1707 91.8715 83.9047 91.7623L36.7009 72.0966V10.5398C36.7016 9.9955 36.9172 9.47365 37.3005 9.08875C37.6839 8.70385 38.2036 8.48734 38.7458 8.4867H129.303C129.845 8.48734 130.365 8.70385 130.748 9.08875C131.131 9.47365 131.347 9.9955 131.347 10.5398V72.1396L131.259 72.1777L85.6063 91.7527C85.3329 91.868 85.0393 91.9274 84.7428 91.9271Z"
          fill="white"
        />
        <path
          d="M84.7429 92.0737C84.4364 92.074 84.1328 92.0144 83.8491 91.8981L36.5549 72.1946V10.5398C36.5556 9.95659 36.7866 9.39745 37.1974 8.98506C37.6081 8.57266 38.165 8.34069 38.7458 8.34003H129.303C129.884 8.34069 130.44 8.57266 130.851 8.98506C131.262 9.39745 131.493 9.95659 131.494 10.5398V72.2364L85.6637 91.8875C85.3722 92.0106 85.0591 92.0739 84.7429 92.0737ZM37.1392 71.8028L84.0719 91.3557C84.5096 91.5341 84.9999 91.5311 85.4355 91.3476L130.909 71.8492V10.5398C130.909 10.1121 130.739 9.7021 130.438 9.39968C130.137 9.09726 129.729 8.92714 129.303 8.92663H38.7458C38.3199 8.92714 37.9115 9.09726 37.6103 9.39968C37.3091 9.7021 37.1397 10.1121 37.1392 10.5398L37.1392 71.8028Z"
          fill="#3F3D56"
        />
        <path
          d="M164.795 57.6151H164.737L131.201 71.9929L85.3183 91.6647C85.139 91.7405 84.9466 91.78 84.7521 91.781C84.5576 91.782 84.3648 91.7445 84.1847 91.6706L36.8469 71.9518L2.48783 57.6386L2.43534 57.6151H2.37686C1.83473 57.6157 1.31496 57.8322 0.931611 58.2171C0.548261 58.602 0.332633 59.1239 0.332031 59.6682V148.833C0.332634 149.377 0.548261 149.899 0.931611 150.284C1.31496 150.669 1.83473 150.885 2.37686 150.886H164.795C165.337 150.885 165.857 150.669 166.24 150.284C166.624 149.899 166.839 149.377 166.84 148.833V59.6682C166.839 59.1239 166.624 58.602 166.24 58.2171C165.857 57.8322 165.337 57.6157 164.795 57.6151ZM166.256 148.833C166.255 149.221 166.102 149.594 165.828 149.869C165.554 150.144 165.182 150.299 164.795 150.299H2.37686C1.98954 150.299 1.61812 150.144 1.34424 149.869C1.07036 149.594 0.916424 149.221 0.916269 148.833V59.6682C0.916799 59.289 1.06331 58.9246 1.32517 58.6513C1.58703 58.378 1.94397 58.2169 2.32138 58.2017L36.8469 72.5853L83.9599 92.2132C84.4699 92.4216 85.0414 92.4184 85.549 92.2044L131.201 72.6293L164.853 58.2017C165.23 58.218 165.586 58.3796 165.847 58.6527C166.109 58.9259 166.255 59.2896 166.256 59.6682V148.833Z"
          fill="#3F3D56"
        />
        <path
          d="M84.2709 74.4719C82.5283 74.4752 80.8319 73.9094 79.4375 72.8599L79.3511 72.7947L61.1489 58.8019C60.3059 58.1534 59.5984 57.3445 59.0668 56.4215C58.5351 55.4984 58.1898 54.4793 58.0504 53.4222C57.911 52.3652 57.9804 51.2909 58.2545 50.2607C58.5287 49.2306 59.0022 48.2647 59.6481 47.4183C60.294 46.5719 61.0996 45.8616 62.0189 45.3278C62.9383 44.794 63.9533 44.4472 65.0061 44.3073C66.0589 44.1674 67.1288 44.237 68.1548 44.5122C69.1808 44.7875 70.1427 45.2629 70.9857 45.9115L82.7757 54.9886L110.637 18.5055C111.284 17.6593 112.089 16.9492 113.009 16.4157C113.929 15.8822 114.944 15.5358 115.997 15.3963C117.049 15.2567 118.119 15.3268 119.145 15.6024C120.171 15.878 121.133 16.3539 121.976 17.0027L121.802 17.2389L121.98 17.0062C123.68 18.318 124.792 20.2533 125.073 22.3875C125.353 24.5217 124.779 26.6805 123.477 28.3903L90.7047 71.3031C89.9467 72.292 88.972 73.0923 87.8563 73.6418C86.7405 74.1913 85.5137 74.4754 84.2709 74.4719Z"
          fill="#007AFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1024_1281">
          <rect
            width="166.508"
            height="150.076"
            fill="white"
            transform="translate(0.332031 0.80957)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
