import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IOption } from 'components/Form'

import { reportOptions } from 'config/report'

import { ReportContext } from 'contexts/report'

import { useTranslation } from 'hooks/useTranslation'
import { useToast } from 'hooks/useToast'
import { useRequestReport } from 'hooks/useRequestReport'

import { IReportTypes } from 'interfaces/report'

export const usePageReportDetails = () => {
  const { translate } = useTranslation()

  const { success, error } = useToast()

  const { createReport } = useRequestReport()

  const navigate = useNavigate()

  const { reportData, setReportData } = useContext(ReportContext)

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  const renderSubtypeOptions = useCallback((): IOption[] => {
    return reportOptions.type[reportData.type?.value! as IReportTypes].map(
      (option) => ({
        label: translate(
          `report.new.reportStep.steps.reportDetails.form.subType.${reportData.type?.value}.options.${option}`
        ),
        value: option
      })
    )
  }, [reportData.type?.value, translate])

  const renderToWhoOptions = useCallback(() => {
    return reportOptions.toWho.map((option) => ({
      label: translate(
        `report.new.reportStep.steps.reportDetails.form.toWho.options.${option}`
      ),
      value: option
    }))
  }, [translate])

  const onChange = (name: string, value: any) => {
    setReportData({ ...reportData, [name]: value })
  }

  const isDisabledSubmit = useCallback(
    () => !reportData.subType || !reportData.toWho || !reportData.acceptTerms,
    [reportData.acceptTerms, reportData.subType, reportData.toWho]
  )

  const onSubmit = useCallback(() => {
    if (isDisabledSubmit()) return
    ;(async () => {
      setIsSubmitLoading(true)

      try {
        const reportResponse: any = await createReport(reportData)

        success(
          translate(
            'report.new.reportStep.steps.reportDetails.form.submit.success'
          )
        )

        navigate(`/report-channel/follow-report/${reportResponse.code}`)
      } catch (err) {
        error(
          translate(
            'report.new.reportStep.steps.reportDetails.form.submit.error'
          )
        )
      } finally {
        setIsSubmitLoading(false)
      }
    })()
  }, [
    createReport,
    error,
    isDisabledSubmit,
    navigate,
    reportData,
    success,
    translate
  ])

  return {
    translate,
    reportData,
    renderSubtypeOptions,
    renderToWhoOptions,
    onChange,
    onSubmit,
    isSubmitLoading
  }
}
