import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: min(58rem, 100%);
  padding: 0 2rem;
  margin: 0 auto;
`

export const ReportArticle = styled.article`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 6.25rem;

    h2,
    p {
      text-align: center;
    }

    @media ${theme.breakpoints.mobile} {
      flex-direction: column;
      align-items: center;
      margin-top: 2.5rem;
    }
  `}
`

export const CreateReport = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(23rem, 100%);
    padding-right: 1rem;

    .ui-intuix-button {
      margin-top: 2rem;
    }

    @media ${theme.breakpoints.mobile} {
      padding-right: 0;
    }
  `}
`

export const ReportDivider = styled.div`
  ${({ theme }) => css`
    height: 28rem;
    width: 2px;
    background-color: var(--gray-50);

    @media ${theme.breakpoints.mobile} {
      display: none;
    }
  `}
`

export const FollowReport = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(23rem, 100%);
    padding-left: 1rem;

    .ui-intuix-button {
      margin: 0 auto;
    }

    p {
      margin-bottom: 1rem;
    }

    @media ${theme.breakpoints.mobile} {
      margin-top: 2rem;
      padding-left: 0;
    }
  `}
`
