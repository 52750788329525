import { css } from 'styled-components'

export const TabsStyles = css`
  :root {
    --tabs-header-background: transparent;
    --tabs-header-color: var(--gray-600);
    --tabs-selected-background: transparent;
    --tabs-selected-color: var(--primary-300);
    --tabs-disabled-background: transparent;
    --tabs-disabled-color: var(--gray-40);
    --tabs-success-background: transparent;
    --tabs-success-color: var(--success);
    --tabs-error-background: transparent;
    --tabs-error-color: var(--danger);
  }

  .tabs-header {
    display: flex;
    height: var(--tabs-header-height);
    align-items: flex-end;

    @media ${(props) => props.theme.breakpoints.mobile} {
      height: auto;
    }

    & > header h1 {
      color: var(--tabs-selected-color);
    }

    .intuix-ui-col {
      display: flex;
      align-items: flex-end;
    }
  }

  .tabs-head {
    && {
      text-align: center;
      border-bottom-width: 0;
      margin-bottom: -0.1rem;
      border-bottom: 2px solid var(--gray-500);

      h1 {
        color: var(--gray-500);
      }

      & span {
        background-color: var(--gray-600);
      }

      &.disabled {
        border-color: var(--gray-40);
        h1 {
          color: var(--gray-40);
        }
        & span {
          background-color: var(--gray-40);
        }
      }

      &.selected {
        border-color: var(--primary-300);
        h1 {
          color: var(--primary-300);
        }
        & span {
          background-color: var(--primary-300);
        }
      }
    }
  }
`
