export const report = {
  new: {
    tabs: {
      report: 'Relato',
      confirmation: 'Confirmação'
    },
    reportStep: {
      steps: {
        reportType: {
          tabTitle: 'Tipo do relato',
          title: 'Relato',
          subtitle:
            'Insira sua relação com a empresa e a situação da ocorrência para que possamos analisar',
          form: {
            orgRelationship: {
              label: 'Relação com a organização *',
              placeholder: 'Selecione',
              options: {
                COLLABORATOR: 'Colaborador(a)',
                CUSTOMER: 'Cliente',
                FURNISHER: 'Fornecedor(a)',
                CONSUMER: 'Consumidor(a)',
                CANDIDATE: 'Candidato(a)',
                FREELANCER: 'Freelancer',
                PROVIDER: 'Prestador(a)'
              }
            },
            type: {
              label: 'Classifique seu relato *',
              placeholder: 'Selecione',
              options: {
                PRAISE: 'Elogio',
                SUGGESTION: 'Sugestão',
                HARASSMENT: 'Assédio',
                DISCRIMINATION: 'Discriminação',
                PERSONAL_DATA: 'Dados Pessoais',
                EVIRORMENT_PROBLEMS: 'Problemas Ambientais',
                SOCIAL_PROBLEMS: 'Problemas Sociais',
                GOVERNANCE_PROBLEMS: 'Problemas de Governança'
              }
            },
            button: 'Próximo'
          }
        },
        reportDetails: {
          tabTitle: 'Detalhes do relato',
          form: {
            subType: {
              PRAISE: {
                label: 'Classifique seu elogio? *',
                placeholder: 'Selecione',
                options: {
                  PRAISE_EMPLOYEE: 'Elogio sobre funcionários',
                  PRAISE_COMPANY: 'Elogio sobre a empresa',
                  PRAISE_PRODUCTS: 'Elogio sobre produtos',
                  PRAISE_SERVICES: 'Elogio sobre serviços',
                  PRAISE_OTHER: 'Outros elogios'
                }
              },
              SUGGESTION: {
                label: 'Classifique seu sugestão? *',
                placeholder: 'Selecione',
                options: {
                  SUGGESTION_EMPLOYEE: 'Sugestão sobre funcionários',
                  SUGGESTION_COMPANY: 'Sugestão sobre a empresa',
                  SUGGESTION_PRODUCTS: 'Sugestão sobre produtos',
                  SUGGESTION_SERVICES: 'Sugestão sobre serviços',
                  SUGGESTION_OTHER: 'Outras Sugestões'
                }
              },
              HARASSMENT: {
                label: 'Qual o tipo de assédio foi sofrido ou observado? *',
                placeholder: 'Selecione',
                options: {
                  MORAL_HARASSMENT: 'Assédio Moral',
                  SEXUAL_HARASSMENT: 'Assédio Sexual',
                  PSYCHOLOGICAL_HARASSMENT: 'Assédio Psicológico',
                  STALKING: 'Stalking',
                  BULLYING: 'Bullying'
                }
              },
              DISCRIMINATION: {
                label:
                  'Qual o tipo de discriminação foi sofrido ou observado? *',
                placeholder: 'Selecione',
                options: {
                  RACE: 'Raça',
                  SEXUAL_ORIENTATION: 'Orientação Sexual',
                  ETHNIC_BACKGROUND: 'Origem étnica',
                  FUNCTIONAL_DIVERSITY: 'Diversidade funcional',
                  GENDER: 'Gênero',
                  APPEARANCE: 'Aparência',
                  AGE: 'Idade',
                  WOMAN_VIOLENCE: 'Violência contra mulher',
                  MARITAL_STATUS: 'Estado civil',
                  NATIONALITY: 'Nacionalidade',
                  PHYSICAL_DISABILITY: 'Deficiência Física',
                  MENTAL_DISABILITY: 'Deficiência Mental',
                  NEURODIVERSITY: 'Neurodiversidade',
                  MATERNITY: 'Maternidade',
                  PATERNITY: 'Parternidade',
                  RELIGION: 'Religião'
                }
              },
              PERSONAL_DATA: {
                label:
                  'Qual o tipo de tratamento de dados pessoais observado? *',
                placeholder: 'Selecione',
                options: {
                  INCORRECT_DATA_HANDLING: 'Tratamento Incorreto de Dados',
                  DATA_LEAKAGE: 'Vazamento de Dados',
                  DOES_NOT_REQUEST_THE_CONSENT_OF_THE_DATA_SUBJECT: 'Não Solicita o Consentimento do Titular dos Dados',
                  INCORRECT_DATA_DISPOSAL: 'Descarte Incorreto de Dados',
                  SALE_OF_PERSONAL_DATA: 'Venda de Dados Pessoais',
                  INAPPROPRIATE_SHARING_OF_PERSONAL_DATA: 'Compartilhamento Inadequado de Dados Pessoais',
                  LACK_OF_TRANSPARENCY_REGARDING_DATA_PROCESSING: 'Falta de Transparência a Respeito do Tratamento de Dados',
                  UNAVAILABILITY_OF_MEANS_TO_REVOKE_ACCEPTANCE_OF_TREATMENT: 'Indisponibilidade de Meios para Revogar o Aceite de Tratamento',
                  INAPPROPRIATE_USE_OF_COOKIES: 'Uso Inadequado de Cookies',
                  INADEQUACY_IN_THE_CONSULTATION_ON_THE_FORM_DURATION_OF_TREATMENT: 'Inadequação na Consulta Sobre a Forma/Duração do Tratamento',
                  IMPROPER_TREATMENT_OF_SENSITIVE_DATA: 'Tratamento Indevido de Dados Sensíveis',
                  IMPROPER_TREATMENT_OF_DATA_OF_MINORS: 'Tratamento Indevido de Dados de Menores'
                }
              },
              EVIRORMENT_PROBLEMS: {
                label:
                  'Qual o tipo de problema ambiental observado? *',
                placeholder: 'Selecione',
                options: {
                  LACK_OF_CONCERN_WITH_ENVIRONMENTAL_ASPECTS: 'Falta de Preocupação com Aspectos Ambientais',
                  IMPROPER_WASTE_DISPOSAL: 'Descarte Indevido de Resíduos',
                  AIR_POLLUTION: 'Poluição do Ar',
                  LACK_OF_ENVIRONMENTAL_AWARENESS_PRACTICES: 'Falta de Práticas de Conscientização Ambiental',
                  CONTRIBUTION_TO_DEFORESTATION: 'Contribuição com Desmatamento',
                  LACK_OF_RECYCLING_PRACTICES: 'Falta de Práticas de Reciclagem',
                  MISUSE_OF_NATURAL_RESOURCES: 'Uso Indevido de Recursos Naturais'
                }
              },
              SOCIAL_PROBLEMS: {
                label:
                  'Qual o tipo de problema social observado? *',
                placeholder: 'Selecione',
                options: {
                  LACK_OF_EQUAL_PAY_BETWEEN_MEN_AND_WOMEN: 'Falta de Igualdade Salarial Entre Homens e Mulheres',
                  LACK_OF_DIVERSITY_AND_INCLUSION_PRACTICES: 'Falta de Práticas de Diversidade e Inclusão',
                  FEMALE_POSITIONS_DO_NOT_PERMEATE_HIERARCHICAL_LEVELS: 'Os Cargos Femininos Não Permeiam os Níveis Hierárquicos',
                  DOES_NOT_RESPECT_HUMAN_RIGHTS: 'Não Respeita os Direitos Humanos',
                  DOES_NOT_FOLLOW_LABOR_LAWS: 'Não Segue as Leis Trabalhistas',
                  DOES_NOT_ENCOURAGE_PRACTICES_TO_ELIMINATE_DISCRIMINATION: 'Não Estimula Práticas para Eliminar a Discriminação'
                }
              },
              GOVERNANCE_PROBLEMS: {
                label:
                  'Qual o tipo de problema de governança observado? *',
                placeholder: 'Selecione',
                options: {
                  DOES_NOT_HAVE_ANTI_CORRUPTION_PRACTICES: 'Não tem Práticas Anticorrupção',
                  CORRUPTION_CASE: 'Caso de Corrupção',
                  CODE_OF_CONDUCT_IS_NOT_COMPLIED_WITH: 'Código de Conduta Não é Cumprido',
                  EVADE_TAXES: 'Sonega Impostos',
                  DOES_NOT_COMPLY_WITH_THE_LAW: 'Não Cumpre a Legislação',
                  DOES_NOT_COMPLY_WITH_ESG_PRACTICES: 'Não Cumpre Práticas ESG'
                }
              }
            },
            toWho: {
              label: 'A situação aconteceu com quem? *',
              placeholder: 'Selecione',
              options: {
                ME: 'Comigo',
                OTHERS: 'Com outra(s) pessoa(s)',
                ME_AND_OTHERS: 'Comigo e com outra(s) pessoa(s)'
              }
            },
            acceptTerms: {
              label:
                'Declaro que li e aceito os termos da <a href="https://intuix.com.br/politica-de-privacidade/">Política de Privacidade</a>.'
            },
            submit: {
              button: 'Finalizar',
              error:
                'Não foi possível concluir o relato. Por favor, tente novamente.',
              success: 'Relato criado com sucesso.'
            }
          }
        }
      }
    },
    confirmationStep: {
      title: 'Relato entregue com sucesso!',
      instructions:
        'Analisaremos seu relato e tomaremos as devidas providências. Copie e salve o código abaixo em um lugar seguro, voce irá utiliza-lo para acompanhar o andamento do seu relato.',
      button: 'Copiar Código',
      copy: {
        success: 'Código copiado com sucesso!'
      }
    }
  }
}
