import React, { useContext } from 'react'
import { PaperPlaneTilt } from 'phosphor-react'

import { Form } from 'components/Form'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'

import { useValidations } from 'hooks/useValidations'
import { useRequestCustomer } from 'hooks/useRequestCustomer'
import { useLoading } from 'hooks/useLoading'
import { useToast } from 'hooks/useToast'

import { PaymentContext } from 'contexts/payment'

import { IPaymentData, IPaymentStep } from 'interfaces/payment'

import { IRequestEcommerceChargeDTO } from 'dtos/IRequestEcommerceChargeDTO'

import { SignUpInputs } from './SignUpInputs'

import * as S from './styles'

export const SignUpStep: React.FC<IPaymentStep> = ({
  onNext,
  onOpenCheckoutListModal
}) => {
  const { warning, error } = useToast()

  const { validatePhone } = useValidations()

  const { createEcommerceCharge } = useRequestCustomer()

  const { loading } = useLoading()

  const { paymentData } = useContext(PaymentContext)

  const parseEcommerceChargeDTO = (payment: IPaymentData) => {
    const requestObject: IRequestEcommerceChargeDTO = {
      personalName: payment.customer.personalName,
      personalEmail: payment.customer.personalEmail,
      personalPhone: payment.customer.personalPhone,
      companyName: payment.customer.companyName.toUpperCase(),
      productsIds: payment.cart.items.map((item) => item.id)
    }

    return requestObject
  }

  const onSubmitSignUpCustomer = async () => {
    const loadingId = loading()

    try {
      if (!validatePhone(paymentData.customer.personalPhone)) {
        loading(loadingId)
        return
      }

      await createEcommerceCharge(parseEcommerceChargeDTO(paymentData))

      onNext()
    } catch (e: any) {
      if (e.code === 'ECR0032') {
        warning('Email de contato já cadastrado')
        return
      }

      error('Finalizar cadastro de compra falhou!')
    } finally {
      loading(loadingId)
    }
  }

  return (
    <Form onSubmit={onSubmitSignUpCustomer}>
      <S.Wrapper>
        <header>
          <div />

          <h2>Finalize seu Cadastro</h2>

          <Button type="button" onClick={onOpenCheckoutListModal}>
            <Icons.ShoppingCart />
          </Button>
        </header>

        <div>
          <section>
            <SignUpInputs />
          </section>

          <footer>
            <Button type="submit" data-cy="signup-step-continue">
              <i>
                <PaperPlaneTilt size={24} />
              </i>
              Realizar Cadastro
            </Button>
          </footer>
        </div>
      </S.Wrapper>
    </Form>
  )
}
