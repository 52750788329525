import styled from 'styled-components'

import { Item as UIItem } from 'components/Form'

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 27.2rem;
  max-width: 100%;
`

export const Item = styled(UIItem)`
  margin-top: 1.5rem;
  padding: 0;
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;

  & button {
    margin-left: auto;
    margin-right: auto;
  }
`
