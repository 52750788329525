export const followReport = {
  error: 'Código inválido. Digite um código válido.',
  number: 'Protocolo #',
  notice:
    'As mensagens enviadas para a organização serão avaliadas e respondidas em breve.',
  message: {
    placeholder: 'Digite sua mensagem...',
    error: 'Não foi possível enviar a mensagem. Por favor, tente novamente.'
  },
  initialMessages: {
    message1:
      'Olá, recebemos o seu relato com a seguinte classificação: {{type}}.',
    message2:
      'Copie o código do relato para acessá-lo novamente mais tarde: {{code}}',
    message3:
      'Precisamos de mais detalhes sobre o seu relato. Por favor, descreva algumas informações que sejam relevantes para nossa verificação da melhor forma possível.',
    message4:
      'Ahh, não se preocupe!! É a nossa obrigação manter o sigilo sobre os relatos.'
  },
  finishSession: {
    button: 'Finalizar relato',
    modal: {
      confirmation: 'Você realmente deseja finalizar essa chamada?',
      finish: 'Finalizar',
      stay: 'Permanecer'
    },
    error: 'Não foi possível finalizar a sessão. Por favor, tente novamente.'
  },
  finishedSession: {
    modal: {
      title: 'Sessão Finalizada',
      paragraph:
        'A sessão foi encerada. Você poderá fazer outros relatos no menu da ouvidoria.',
      button: 'Entendido'
    }
  }
}
