import { css } from 'styled-components'

export const TableStyles = css`
  :root {
    --table-shadow: var(--shadow);
    --table-border-color: var(--gray-40);
    --table-color: var(--gray-300);
    --table-color-selected: var(--black);
    --thead-background: var(--gray-40);
    --tbody-background-hover: var(--gray-30);
    --tbody-color-hover: var(--black);
  }

  .table-header > h1 {
    font-weight: 700;
  }
`
