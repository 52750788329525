import { createContext, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ITabsRef } from 'components/Tabs'

import { useTranslation } from 'hooks/useTranslation'
import { useLoading } from 'hooks/useLoading'
import { useToast } from 'hooks/useToast'
import { useRequestProposal } from 'hooks/useRequestProposal'

interface IProposalContext {
  onAcceptProposal: () => void
  onComplete: (code: string) => void
}

export const ProposalContext = createContext({} as IProposalContext)

interface IProposalContextProviderProps {
  proposalTabsRef: React.RefObject<ITabsRef>
  isPassedProposal: boolean
  setIsPassedProposal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProposalContextProvider: React.FC<
  IProposalContextProviderProps
> = ({ children, proposalTabsRef, isPassedProposal, setIsPassedProposal }) => {
  const { translate } = useTranslation()

  const { loading } = useLoading()

  const { error } = useToast()

  const navigate = useNavigate()

  const { sendSubscriberValidationCode, validateSubscriberCode } =
    useRequestProposal()

  const { subscriberId } = useParams()

  const gotoTab = useCallback(
    (nextTab: number) => {
      proposalTabsRef.current!.gotoTab(nextTab)
    },
    [proposalTabsRef]
  )

  const onAcceptProposal = useCallback(() => {
    ;(async () => {
      const loadingId = loading()

      try {
        if (!isPassedProposal) {
          await sendSubscriberValidationCode(subscriberId!)

          setIsPassedProposal(true)
        }

        gotoTab(2)

        window.scrollTo(0, 0)
      } catch (err) {
        error(translate('proposal.proposalStep.sendCode.error'))
      } finally {
        loading(loadingId)
      }
    })()
  }, [
    error,
    gotoTab,
    isPassedProposal,
    loading,
    sendSubscriberValidationCode,
    setIsPassedProposal,
    subscriberId,
    translate
  ])

  const onComplete = useCallback(
    (codeValue: string) => {
      ;(async () => {
        const loadingId = loading()

        try {
          await validateSubscriberCode({
            code: codeValue,
            subscriberId: subscriberId!
          })

          navigate('/feed?popup=PROPOSAL_SIGNED')
        } catch (err) {
          error(translate('proposal.authenticationStep.authenticate.error'))
        } finally {
          loading(loadingId)
        }
      })()
    },
    [error, loading, navigate, subscriberId, translate, validateSubscriberCode]
  )

  return (
    <ProposalContext.Provider value={{ onAcceptProposal, onComplete }}>
      {children}
    </ProposalContext.Provider>
  )
}
