import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  type?: 'p' | 'span' | 'rte'
}

export const InnerHTML: React.FC<Props> = ({ text, type }) => {
  const { t: translate } = useTranslation()

  switch (type) {
    case 'span':
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: translate(text, {
              interpolation: { escapeValue: false }
            })
          }}
        />
      )

    case 'rte':
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: translate(text, {
              interpolation: { escapeValue: false }
            })
          }}
        />
      )

    default:
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: translate(text, {
              interpolation: { escapeValue: false }
            })
          }}
        />
      )
  }
}
