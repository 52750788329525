import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`
