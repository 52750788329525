import styled from 'styled-components'
import { Container as UIContainer } from 'components/Container'

export const Container = styled(UIContainer)``

export const Wrapper = styled.div`
  scroll-behavior: smooth;

  .tabs-header {
    border-bottom: 1px solid var(--tabs-disabled-color);

    > div {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .tabs-head {
    text-align: center;
    border-bottom-width: 0;
    margin-bottom: -0.1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    & p {
      color: var(--tabs-header-color);
    }

    & span {
      background-color: var(--tabs-header-color);
    }
  }

  .selected {
    border-bottom-width: 3px;

    & p {
      color: var(--tabs-selected-color);
    }

    & span {
      background-color: var(--tabs-selected-color);
    }
  }

  .disabled {
    & p {
      color: var(--tabs-disabled-color);
    }

    & span {
      background-color: var(--tabs-disabled-color);
    }
  }
`

export const Section = styled.div`
  padding: 2.6rem 0;
`

export const Tab = styled.p`
  display: flex;
  justify-content: center;
  margin: 0;

  & span {
    display: inline-block;
    border-radius: 99px;
    text-align: center;
    width: 1.6rem;
    line-height: 1.6rem;
    font-size: 0.875rem;
    align-self: center;
    background-color: var(--tabs-disabled-color);
    color: var(--white);
    margin-right: 0.875rem;
  }
`
