import React from 'react'
import { ILoading } from '@desenvolvimento-intuix/ui-loading'

import { Logo } from '../components/Logo'

export const loadingConfig: ILoading = {
  children: <Logo width="20rem" />,
  loader: {
    color: 'var(--primary-300)'
  }
}
