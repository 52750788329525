import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 33.5rem;
  margin: 3.5rem auto 0;

  h3 {
    font-weight: 700;
  }

  p {
    margin-bottom: 1rem;
  }

  .select-container {
    z-index: 999999;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`
