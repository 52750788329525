import styled, { css } from 'styled-components'

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 48rem;
    margin: 0 auto;
    text-align: center;

    img,
    h3 {
      width: min(19rem, 100%);
      margin: 0;
    }

    img {
      object-fit: cover;
    }

    @media ${theme.breakpoints.mobile} {
      flex-direction: column;
      img {
        margin-bottom: 1rem;
      }
    }
  `}
`

export const LogosDivider = styled.div`
  ${({ theme }) => css`
    height: 3.5rem;
    width: 2px;
    background-color: var(--gray-50);
    @media ${theme.breakpoints.mobile} {
      display: none;
    }
  `}
`
