import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 78rem;
  margin-inline: auto;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 2rem;

    button {
      padding: 0;

      height: 42px;
      width: 42px;

      flex: 0 0 42px;
    }
  }

  & > div {
    max-width: 42rem;
    margin: 0 auto;

    & > footer {
      margin-top: 2rem;

      display: flex;
      justify-content: center;
    }
  }
`

export const Title = styled.h4`
  font-size: 1.125rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.75rem;
`

export const SummaryWrapper = styled.div`
  padding-left: 1rem;
`

export const BackWrapper = styled.div`
  width: 33%;
  padding-right: 0.5rem;
`

export const FinishWrapper = styled.div`
  width: 67%;
  padding-left: 0.5rem;
`
