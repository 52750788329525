export const reportChannel = {
  error:
    'Não foi possível mostrar o canal de denúncia. Talvez o link esteja incorreto.',
  logos: {
    alt: {
      reportChannel: 'Logo do canal de denúncias',
      cebrasse: 'Logo da Central Brasileira do Setor de Serviços'
    }
  },
  createReport: {
    title: 'Criar Relato',
    instructions:
      'Crie seu relato e envie para que nossa equipe possa analisar e buscar a melhor solução!',
    button: 'Criar Relato'
  },
  followReport: {
    title: 'Acompanhar Relato',
    instructions:
      'Acompanhe seu relato com o código disponibilizado quando foi feito.',
    code: {
      label: 'Digite o código do protocolo',
      placeholder: 'XXXXX'
    },
    submit: {
      button: 'Acompanhar Relato',
      error: 'Não foi possível acessar o relato. Por favor, tente novamente.'
    }
  }
}
