import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch
} from 'react-router-dom'

import { Login } from 'pages/Login'
import { EmailConfirmation } from 'pages/EmailConfirmation'
import { EmailSent } from 'pages/EmailSent'
import { ForgotPassword } from 'pages/ForgotPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { Payment } from 'pages/Payment'

import { ReportChannel } from 'pages/_ReportChannel/ReportChannel'
import { Report } from 'pages/_ReportChannel/Report'
import { FollowReport } from 'pages/_ReportChannel/FollowReport'

import { Proposal } from 'pages/Proposal'

import { NotFound } from 'pages/NotFound'
import { ReportChannelBanner } from 'pages/_ReportChannel/ReportChannelBanner'

import { Feed } from 'pages/Feed'

export const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Navigate to="login" />} />

        <Route path="/feed" element={<Feed />} />

        <Route path="/login" element={<Login />} />
        <Route path="/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/store" element={<Payment />} />

        <Route path="/proposal/:subscriberId" element={<Proposal />} />
        <Route path="/report-channel" element={<ReportChannel />} />
        <Route path="/report-channel/report" element={<Report />} />
        <Route
          path="/customers/:customerId/report-channel-banner"
          element={<ReportChannelBanner />}
        />
        <Route
          path="/report-channel/follow-report/:reportCode"
          element={<FollowReport />}
        />

        <Route path="*" element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  )
}
