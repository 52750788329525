export const reportOptions = {
  orgRelationship: [
    'COLLABORATOR',
    'CUSTOMER',
    'FURNISHER',
    'CONSUMER',
    'CANDIDATE',
    'FREELANCER',
    'PROVIDER'
  ],
  type: {
    /* DATA_TREATMENT: [], */
    PRAISE: [
      "PRAISE_EMPLOYEE",
      "PRAISE_COMPANY",
      "PRAISE_PRODUCTS",
      "PRAISE_SERVICES",
      "PRAISE_OTHER",
    ],
    SUGGESTION: [
      "SUGGESTION_EMPLOYEE",
      "SUGGESTION_COMPANY",
      "SUGGESTION_PRODUCTS",
      "SUGGESTION_SERVICES",
      "SUGGESTION_OTHER",
    ],
    DISCRIMINATION: [
      'RACE',
      'SEXUAL_ORIENTATION',
      'ETHNIC_BACKGROUND',
      'FUNCTIONAL_DIVERSITY',
      'GENDER',
      'APPEARANCE',
      'AGE',
      'WOMAN_VIOLENCE',
      'MARITAL_STATUS',
      'NATIONALITY',
      'PHYSICAL_DISABILITY',
      'MENTAL_DISABILITY',
      'NEURODIVERSITY',
      'MATERNITY',
      'PATERNITY',
      'RELIGION'
    ],
    HARASSMENT: [
      'MORAL_HARASSMENT',
      'SEXUAL_HARASSMENT',
      'PSYCHOLOGICAL_HARASSMENT',
      'STALKING',
      'BULLYING'
    ],
    PERSONAL_DATA: [
      'INCORRECT_DATA_HANDLING',
      'DATA_LEAKAGE',
      'DOES_NOT_REQUEST_THE_CONSENT_OF_THE_DATA_SUBJECT',
      'INCORRECT_DATA_DISPOSAL',
      'SALE_OF_PERSONAL_DATA',
      'INAPPROPRIATE_SHARING_OF_PERSONAL_DATA',
      'LACK_OF_TRANSPARENCY_REGARDING_DATA_PROCESSING',
      'UNAVAILABILITY_OF_MEANS_TO_REVOKE_ACCEPTANCE_OF_TREATMENT',
      'INAPPROPRIATE_USE_OF_COOKIES',
      'INADEQUACY_IN_THE_CONSULTATION_ON_THE_FORM_DURATION_OF_TREATMENT',
      'IMPROPER_TREATMENT_OF_SENSITIVE_DATA',
      'IMPROPER_TREATMENT_OF_DATA_OF_MINORS'
    ],
    EVIRORMENT_PROBLEMS: [
      'LACK_OF_CONCERN_WITH_ENVIRONMENTAL_ASPECTS',
      'IMPROPER_WASTE_DISPOSAL',
      'AIR_POLLUTION',
      'LACK_OF_ENVIRONMENTAL_AWARENESS_PRACTICES',
      'CONTRIBUTION_TO_DEFORESTATION',
      'LACK_OF_RECYCLING_PRACTICES',
      'MISUSE_OF_NATURAL_RESOURCES'
    ],
    SOCIAL_PROBLEMS: [
      'LACK_OF_EQUAL_PAY_BETWEEN_MEN_AND_WOMEN',
      'LACK_OF_DIVERSITY_AND_INCLUSION_PRACTICES',
      'FEMALE_POSITIONS_DO_NOT_PERMEATE_HIERARCHICAL_LEVELS',
      'DOES_NOT_RESPECT_HUMAN_RIGHTS',
      'DOES_NOT_FOLLOW_LABOR_LAWS',
      'DOES_NOT_ENCOURAGE_PRACTICES_TO_ELIMINATE_DISCRIMINATION'
    ],
    GOVERNANCE_PROBLEMS: [
      'DOES_NOT_HAVE_ANTI_CORRUPTION_PRACTICES',
      'CORRUPTION_CASE',
      'CODE_OF_CONDUCT_IS_NOT_COMPLIED_WITH',
      'EVADE_TAXES',
      'DOES_NOT_COMPLY_WITH_THE_LAW',
      'DOES_NOT_COMPLY_WITH_ESG_PRACTICES'
    ]
  },
  toWho: ['ME', 'OTHERS', 'ME_AND_OTHERS']
}
