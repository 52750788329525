import { createGlobalStyle } from 'styled-components'

import { FormStyles } from './form'
import { LoadingStyles } from './loading'
import { ModalStyles } from './modal'
import { NavStyles } from './nav'
import { TableStyles } from './table'
import { TabsStyles } from './tabs'
import { ToastStyles } from './toast'
import { TypographyStyles } from './typography'

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary-500: ${(props) => props.theme.colors.primary[500]};
    --primary-400: ${(props) => props.theme.colors.primary[400]};
    --primary-300: ${(props) => props.theme.colors.primary[300]};
    --primary-200: ${(props) => props.theme.colors.primary[200]};
    --primary-100: ${(props) => props.theme.colors.primary[100]};
    --primary-75: ${(props) => props.theme.colors.primary[75]};
    --primary-50: ${(props) => props.theme.colors.primary[50]};

    --gray-900: ${(props) => props.theme.colors.gray[900]};
    --gray-800: ${(props) => props.theme.colors.gray[800]};
    --gray-700: ${(props) => props.theme.colors.gray[700]};
    --gray-600: ${(props) => props.theme.colors.gray[600]};
    --gray-500: ${(props) => props.theme.colors.gray[500]};
    --gray-400: ${(props) => props.theme.colors.gray[400]};
    --gray-300: ${(props) => props.theme.colors.gray[300]};
    --gray-200: ${(props) => props.theme.colors.gray[200]};
    --gray-100: ${(props) => props.theme.colors.gray[100]};
    --gray-90: ${(props) => props.theme.colors.gray[90]};
    --gray-80: ${(props) => props.theme.colors.gray[80]};
    --gray-70: ${(props) => props.theme.colors.gray[70]};
    --gray-60: ${(props) => props.theme.colors.gray[60]};
    --gray-50: ${(props) => props.theme.colors.gray[50]};
    --gray-40: ${(props) => props.theme.colors.gray[40]};
    --gray-30: ${(props) => props.theme.colors.gray[30]};
    --gray-20: ${(props) => props.theme.colors.gray[20]};
    --gray-10: ${(props) => props.theme.colors.gray[10]};
    --gray-0: ${(props) => props.theme.colors.gray[0]};

    --white: var(--gray-10);
    --black: var(--gray-800);

    --green-500:  ${(props) => props.theme.colors.green[500]};
    --green-400:  ${(props) => props.theme.colors.green[400]};
    --green-300:  ${(props) => props.theme.colors.green[300]};
    --green-200:  ${(props) => props.theme.colors.green[200]};
    --green-100:  ${(props) => props.theme.colors.green[100]};
    --green-75:  ${(props) => props.theme.colors.green[75]};
    --green-50:  ${(props) => props.theme.colors.green[50]};

    --yellow-500:  ${(props) => props.theme.colors.yellow[500]};
    --yellow-400:  ${(props) => props.theme.colors.yellow[400]};
    --yellow-300:  ${(props) => props.theme.colors.yellow[300]};
    --yellow-200:  ${(props) => props.theme.colors.yellow[200]};
    --yellow-100:  ${(props) => props.theme.colors.yellow[100]};
    --yellow-75:  ${(props) => props.theme.colors.yellow[75]};
    --yellow-50:  ${(props) => props.theme.colors.yellow[50]};

    --red-500:  ${(props) => props.theme.colors.red[500]};
    --red-400:  ${(props) => props.theme.colors.red[400]};
    --red-300:  ${(props) => props.theme.colors.red[300]};
    --red-200:  ${(props) => props.theme.colors.red[200]};
    --red-100:  ${(props) => props.theme.colors.red[100]};
    --red-75:  ${(props) => props.theme.colors.red[75]};
    --red-50:  ${(props) => props.theme.colors.red[50]};

    --blue-500:  ${(props) => props.theme.colors.blue[500]};
    --blue-400:  ${(props) => props.theme.colors.blue[400]};
    --blue-300:  ${(props) => props.theme.colors.blue[300]};
    --blue-200:  ${(props) => props.theme.colors.blue[200]};
    --blue-100:  ${(props) => props.theme.colors.blue[100]};
    --blue-75:  ${(props) => props.theme.colors.blue[75]};
    --blue-50:  ${(props) => props.theme.colors.blue[50]};

    --success: ${(props) => props.theme.colors.feedback['success']};
    --warning: ${(props) => props.theme.colors.feedback['warning']};
    --danger: ${(props) => props.theme.colors.feedback['danger']};

    --shadow: 0.3px 0px 4.4px -3px rgba(0, 0, 0, 1), 0.7px 0px 6.1px -3px
      rgba(0, 0, 0, 0.39), 1.5px 0px 5.9px -3px rgba(0, 0, 0, 0.25), 2.7px 0px
      6px -3px rgba(0, 0, 0, 0.167), 4.2px 0px 11.2px -3px rgba(0, 0, 0, 0.104),
    5px 0px 30px -3px rgba(0, 0, 0, 0.05);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :focus {
    outline: transparent;
  }

  html, body, #root {
    width: 100%;
    min-height: 100vh;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: var(--gray-20);
  }

  hr {
    margin: 1rem 0;
    border-top: 1px solid var(--gray-40);
  }

  ${LoadingStyles}
  ${NavStyles}
  ${TableStyles}
  ${FormStyles}
  ${ModalStyles}
  ${TabsStyles}
  ${ToastStyles}
  ${TypographyStyles}
`
