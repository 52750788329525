import React, { useCallback, useContext } from 'react'

import { Label, Input, IInputOnChange } from 'components/Form'
import { Row, Col } from 'components/Grid'

import { PaymentContext } from 'contexts/payment'

import { useTranslation } from 'hooks/useTranslation'

import * as S from './styles'
import { InnerHTML } from 'components/InnerHTML'

export const SignUpInputs: React.FC = () => {
  const { translate } = useTranslation()

  const { paymentData, setPaymentData } = useContext(PaymentContext)

  const onChange: IInputOnChange = useCallback(
    (name, value) => {
      setPaymentData({
        ...paymentData,
        customer: {
          ...paymentData.customer,
          [name]: value
        }
      })
    },
    [paymentData, setPaymentData]
  )

  const isSelected = useCallback(() => {
    return paymentData.acceptedTerms
  }, [paymentData])

  const onToggle: IInputOnChange = () => {
    setPaymentData({
      ...paymentData,
      acceptedTerms: !paymentData.acceptedTerms
    })
  }

  return (
    <S.SignUpInputsContainer>
      <S.Company>
        <h3>{translate('payment.signUp.form.company.heading')}</h3>

        <S.Item>
          <Label>
            {translate('payment.signUp.form.company.name.label')}
            <Input
              name="companyName"
              type="text"
              placeholder={translate(
                'payment.signUp.form.company.name.placeholder'
              )}
              value={paymentData.customer.companyName}
              onChange={onChange}
              required
            />
          </Label>
        </S.Item>
      </S.Company>

      <S.Personal>
        <h3>{translate('payment.signUp.form.personal.heading')}</h3>
        <S.Item>
          <Label>
            {translate('payment.signUp.form.personal.name.label')}
            <Input
              name="personalName"
              type="text"
              placeholder={translate(
                'payment.signUp.form.personal.name.placeholder'
              )}
              value={paymentData.customer.personalName}
              onChange={onChange}
              required
            />
          </Label>
        </S.Item>
        <Row>
          <Col md={6}>
            <S.Email>
              <S.Item>
                <Label>
                  {translate('payment.signUp.form.personal.email.label')}
                  <Input
                    name="personalEmail"
                    type="email"
                    placeholder={translate(
                      'payment.signUp.form.personal.email.placeholder'
                    )}
                    value={paymentData.customer.personalEmail}
                    onChange={onChange}
                    required
                  />
                </Label>
              </S.Item>
            </S.Email>
          </Col>
          <Col md={6}>
            <S.Phone>
              <S.Item>
                <Label>
                  {translate('payment.signUp.form.personal.phone.label')}
                  <Input
                    name="personalPhone"
                    type="tel"
                    placeholder={translate(
                      'payment.signUp.form.personal.phone.placeholder'
                    )}
                    value={paymentData.customer.personalPhone}
                    onChange={onChange}
                    required
                  />
                </Label>
              </S.Item>
            </S.Phone>
          </Col>
          <S.Terms>
            <S.Item>
              <Input
                {...{
                  id: 'terms',
                  type: 'checkbox',
                  name: 'terms',
                  value: 'terms',
                  onChange: onToggle,
                  checked: isSelected(),
                  required: true,
                  label: (
                    <InnerHTML text='Declaro que li e aceito os <a href="https://develop-storage-email.s3.sa-east-1.amazonaws.com/TERMO-DE-ADESAO-INTUIX-TECNOLOGIA.pdf" target="_blank">Termos de uso</a> e <a href="https://intuix.com.br/politica-de-privacidade/" target="_blank">Política de Privacidade</a>.' />
                  )
                }}
              />
            </S.Item>
          </S.Terms>
        </Row>
      </S.Personal>
    </S.SignUpInputsContainer>
  )
}
