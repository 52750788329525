import styled from 'styled-components'

export const ProductItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  padding: 1rem 1rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  strong {
    font-size: 1.2rem;
    font-weight: 500;

    sup {
      color: var(--gray-300);
      font-size: 0.875rem;
    }
  }

  span {
    width: auto;
  }

  button {
    padding: 0;
    width: 42px;
    height: 42px;
    min-height: 42px;

    flex: 0 0 42px;

    i {
      margin: 0;
    }
  }
`

export const Price = styled.span`
  width: 20%;
  padding: 0 0.1rem;
  text-align: center;
`
