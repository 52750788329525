import { createContext } from 'react'

import { IPaymentContext } from 'interfaces/payment'

export const defaultPaymentData = {
  cart: {
    items: [],
    quantity: 0,
    total: 0
  },
  customer: {
    companyName: '',
    personalName: '',
    personalEmail: '',
    personalPhone: ''
  },
  acceptedTerms: false
}

export const PaymentContextDefaultValues: IPaymentContext = {
  paymentData: defaultPaymentData,
  setPaymentData: () => {},
  findInCart: () => undefined,
  findQuantityInCart: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  clearFromCart: () => {}
}

export const PaymentContext = createContext<IPaymentContext>(
  PaymentContextDefaultValues
)
