import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 18rem;
  max-width: 100%;
`

export const Heading = styled.h2`
  margin-top: 6rem;
`

export const SubTitle = styled.p.attrs({ className: 'subtitle-4' })`
  margin-top: 1rem;
`
export const Code = styled.div`
  margin-top: 6rem;
`

export const Instruction = styled.p.attrs({ className: 'subtitle-1' })`
  margin-bottom: 1rem;
`

export const Loading = styled.div`
  margin-top: 1.5rem;

  & span {
    font-size: 0.75rem;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  display: flex;
  justify-content: center;

  & i svg {
    animation: ${spin} 2s linear both infinite;
  }
`

export const ResendCode = styled.span`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue-500);
  cursor: pointer;

  & span {
    margin-left: 0.625rem;
  }
`
