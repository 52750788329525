import { useCallback } from 'react'

import { IProduct } from 'interfaces/product'

import { productsService } from 'services/productsService'

import { useFormatting } from './useFormatting'
import { IResponseProductDTO } from 'dtos/IResponseProductDTO'

export const useRequestProducts = () => {
  const { parsePriceFloat } = useFormatting()

  const parseRequestProduct = useCallback(
    (productData: IProduct) => ({
      id: productData.id || undefined,
      name: productData.name,
      description: productData.description,
      value: parsePriceFloat(productData.value),
      considerItemsValue: productData.considerItemsValue,
      itemsIds: productData.items.map((item) => item.value)
    }),
    [parsePriceFloat]
  )

  const showProducts = useCallback(
    (query?: string): Promise<IResponseProductDTO[]> => {
      return productsService.get(`products/list${query || ''}`)
    },
    []
  )

  const showProduct = useCallback((id: string) => {
    return productsService.get(`products/listOneDetailed/${id}`)
  }, [])

  const createProduct = useCallback(
    (productData: IProduct) => {
      return productsService.post(
        'products/create',
        parseRequestProduct(productData)
      )
    },
    [parseRequestProduct]
  )

  const updateProduct = useCallback(
    (productData: IProduct) => {
      return productsService.put(
        'products/update',
        parseRequestProduct(productData)
      )
    },
    [parseRequestProduct]
  )

  const removeProduct = useCallback((id: string) => {
    return productsService.patch(`products/inactivate/${id}`)
  }, [])

  return {
    showProducts,
    showProduct,
    createProduct,
    updateProduct,
    removeProduct
  }
}
