import styled from 'styled-components'

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;

  @media ${(props) => props.theme.breakpoints.mobile} {
    & svg {
      width: 10rem;
    }
  }
`
