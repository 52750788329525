import { IRequestEcommerceChargeDTO } from 'dtos/IRequestEcommerceChargeDTO'
import { useCallback } from 'react'

import { customerRelationshipService } from 'services/customerRelationshipService'

export const useRequestCustomer = () => {
  const createEcommerceCharge = (
    ecommerceChargeDTO: IRequestEcommerceChargeDTO
  ): Promise<any> => {
    return customerRelationshipService.post(
      '/charges/create',
      ecommerceChargeDTO
    )
  }

  const showCustomerName = useCallback((id: string) => {
    return customerRelationshipService.get(`customers/listCustomerName/${id}`)
  }, [])

  return { showCustomerName, createEcommerceCharge }
}
