import { useCallback, useState } from 'react'

import { IInputOnChange } from 'components/Form'

import { useToast } from 'hooks/useToast'
import { useTranslation } from 'hooks/useTranslation'

import { IUser } from 'interfaces/user'
import { ILogin } from 'interfaces/authentication'

import { authService } from 'services/authService'

export const useLogin = () => {
  const { translate } = useTranslation()

  const { error } = useToast()

  const initLogin = useCallback(
    () => ({
      identifier: '',
      password: ''
    }),
    []
  )

  const [login, setLogin] = useState<ILogin>(initLogin())

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
      const user: IUser = await authService.post('/auth/login', {
        login: login.identifier,
        password: login.password
      })
      if (user.isAdmin) {
        window.location.replace(
          `${process.env.REACT_APP_INTERNAL_APP}?token=${user.token}`
        )
      } else {
        window.location.replace(
          `${process.env.REACT_APP_MANAGER_APP}?token=${user.token}`
        )
      }
      setLoading(false)
    } catch (e: any) {
      error(
        translate('login.form.error'),
        translate(`errors.authService.${e.code}`)
      )

      setLoading(false)
    }
  }

  const onChange: IInputOnChange = (name, value) => {
    setLogin({
      ...login,
      [name]: value
    })
  }

  return {
    onSubmit,
    onChange,
    login,
    loading
  }
}
