import React from 'react'

import { Form, Label, Input } from 'components/Form'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Container } from 'components/Container'
import { Link } from 'components/Link'

import { useTranslation } from 'hooks/useTranslation'
import { useLogin } from 'hooks/useLogin'

import * as S from './styles'

export const Login: React.FC = () => {
  const { translate } = useTranslation()
  const { onSubmit, onChange, login, loading } = useLogin()

  return (
    <Container
    // nav={
    //   <S.NavWrapper>
    //     <Link to="/payment">
    //       <Button variant="outline">
    //         {translate('login.header.button')}
    //       </Button>
    //     </Link>
    //   </S.NavWrapper>
    // }
    >
      <S.Wrapper>
        <Form onSubmit={onSubmit}>
          <S.Heading>{translate('login.form.title')}</S.Heading>
          <S.Item>
            <Label>
              {translate('login.form.identifier.label')}
              <Input
                name="identifier"
                value={login.identifier}
                placeholder={translate('login.form.identifier.placeholder')}
                onChange={onChange}
                disabled={loading}
                required
              />
            </Label>
          </S.Item>
          <S.Item>
            <Label>
              {translate('login.form.password.label')}
              <Input
                name="password"
                value={login.password}
                type="password"
                placeholder={translate('login.form.password.placeholder')}
                onChange={onChange}
                disabled={loading}
                required
              />
            </Label>
          </S.Item>
          <S.LinkWrapper>
            <Link to="/forgot-password" color="--black">
              {translate('login.form.password.forgot')}
            </Link>
          </S.LinkWrapper>
          <S.ButtonWrapper>
            <Button loading={loading}>
              {translate('login.form.button')}
              <i>
                <Icons.LogIn />
              </i>
            </Button>
          </S.ButtonWrapper>
          {/* <S.ButtonWrapper>
            <span>
              {translate('login.form.createAccount.span')}
              <Link to="/">{translate('login.form.createAccount.link')}</Link>
            </span>
          </S.ButtonWrapper> */}
        </Form>
      </S.Wrapper>
    </Container>
  )
}
