import React from 'react'

import { usePageStatusBarClient } from './usePageStatusBarClient'
import { Check, X } from 'phosphor-react'
import * as S from './styles'

export enum AcessIds {
  ACTIVE = '03968c27-1be2-41ee-9cb0-ec35c03b8997',
  DISABLE = 'e175b1c2-3482-4149-ab01-da84d7965772'
}

export const StatusBarClient: React.FC = () => {
  const { customerState, contractedProducts, notContractedProducts } = usePageStatusBarClient()

  return (
    <div>
      <S.StatusBar>
        {customerState.systemAccessId === AcessIds.ACTIVE ? (
          <button className="statusActive">
            <i>
              <Check size={20} color="black" />
            </i>
            <p>Ativo</p>
          </button>
        ) : customerState.systemAccessId === AcessIds.DISABLE ? (
          <button className="statusDisable">
            <i>
              <X size={20} />
            </i>
            <p>Suspenso</p>
          </button>
        ) : (
          ''
        )}
      </S.StatusBar>

      <S.LogosCertificates>
        <section>
          <ul>
            {contractedProducts.map((product) => (
              <li key={product.id}>
                {product.logo && <img src={product.logo} alt={product.name} />}
              </li>
            ))}
          </ul>
        </section>

        <section>
          <ul>
            {notContractedProducts.map((product) => (
              <li key={product.id}>
                <p>Indisponível</p>
                {product.logo && <img src={product.logo} alt={product.name} />}
              </li>
            ))}
          </ul>
        </section>
      </S.LogosCertificates>
    </div>
  )
}
