import { useCallback, useEffect, useRef } from 'react'

import { useTranslation } from 'hooks/useTranslation'

import { IChatMessages } from '.'

export const usePageChatMessages = ({ messages }: IChatMessages) => {
  const { translate } = useTranslation()

  const chatRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    chatRef.current?.scroll({
      top: chatRef.current.scrollHeight
    })
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [scrollToBottom, messages])

  const getProfilePictureMessageIndexes = useCallback(() => {
    const indexList: number[] = []

    messages.forEach((message, i) => {
      if (
        message.sender === 'thirdPerson' &&
        (!messages[i + 1] || messages[i + 1].sender === 'firstPerson')
      ) {
        indexList.push(i)
      }
    })

    return indexList
  }, [messages])

  return { translate, chatRef, getProfilePictureMessageIndexes }
}
