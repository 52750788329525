import { css } from 'styled-components'

export const ToastStyles = css`
  :root {
    --toast-shadow: var(--shadow);
    --toast-background: var(--white);
    --toast-color: var(--blue-300);
    --toast-border: 1px solid var(--blue-300);
    --toast-success-background: var(--white);
    --toast-success-color: var(--success);
    --toast-success-border: 1px solid var(--success);
    --toast-warning-background: var(--white);
    --toast-warning-color: var(--warning);
    --toast-warning-border: 1px solid var(--warning);
    --toast-error-background: var(--white);
    --toast-error-color: var(--danger);
    --toast-error-border: 1px solid var(--danger);
  }

  #root-toast {
    z-index: 999999999999999999;
  }
`
