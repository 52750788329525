import { Row } from 'components/Grid'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const Header = styled.div`
  margin-top: 1rem;
  text-align: center;

  img {
    width: 100%;
  }
`

export const Section = styled.section`
  margin-bottom: 2rem;
`

export const Scope = styled.div`
  margin-top: 2rem;

  h3 {
    text-align: center;
  }

  h4 {
    margin-top: 1.5rem;
  }
`

export const ProductDetails = styled.div``

export const ProductDetail = styled.div`
  margin-bottom: 1rem;
`

export const Footer = styled.footer`
  a {
    color: var(--blue-500);
    text-decoration: none;
  }

  button {
    margin: 0 auto;
    margin-top: 3rem;
  }
`

export const ProposalStakeholders = styled(Row)`
  margin-bottom: 1.5rem;
`

export const Data = styled.div`
  & span {
    font-weight: 700;
  }

  & + & {
    margin-top: 0.5rem;
  }
`
