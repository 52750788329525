export const resetPassword = {
  form: {
    title: 'Criar nova senha',
    description:
      'Entre e confirme sua nova senha para resetar senha da sua conta. ',
    passwd: {
      label: 'Senha',
      placeholder: 'Digite sua nova senha'
    },
    passwdConfirm: {
      label: 'Confirmar senha',
      placeholder: 'Confirme sua nova senha'
    },
    button: 'Criar nova senha',
    success: 'Sua senha foi atualizada com sucesso',
    errors: {
      default: 'Criar nova senha falhou',
      different: 'As senhas não correspondem. Por favor digite senhas iguais.'
    }
  }
}
