import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { IModalRef } from 'components/Modal'

import { useTranslation } from 'hooks/useTranslation'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'hooks/useLoading'
import { useRequestReport } from 'hooks/useRequestReport'

import { IFollowReport, IMessage } from 'interfaces/followReport'

export const usePageFollowReport = () => {
  const { translate } = useTranslation()

  const { error } = useToast()

  const { loading } = useLoading()

  const navigate = useNavigate()

  const { reportCode } = useParams()

  const { listReport, createMessage, listMessages, finishReport } =
    useRequestReport()

  const [report, setReport] = useState<IFollowReport>()

  const initMessages = useCallback((): IMessage[] => {
    return [
      {
        content: translate('followReport.initialMessages.message1', {
          type: translate(
            `report.new.reportStep.steps.reportType.form.type.options.${report?.reportType}`
          )
        }),
        sender: 'thirdPerson'
      },
      {
        content: translate('followReport.initialMessages.message2', {
          code: reportCode
        }),
        sender: 'thirdPerson'
      },
      {
        content: translate('followReport.initialMessages.message3'),
        sender: 'thirdPerson'
      },
      {
        content: translate('followReport.initialMessages.message4'),
        sender: 'thirdPerson'
      }
    ]
  }, [report?.reportType, reportCode, translate])

  const [messages, setMessages] = useState<IMessage[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const finishSessionModal = useRef<IModalRef>(null)

  const finishedSessionModal = useRef<IModalRef>(null)

  const onOpenFinishModal = () => {
    finishSessionModal.current?.onOpen()
  }

  const onCloseFinishModal = () => {
    finishSessionModal.current?.onClose()
  }

  const onOpenFinishedModal = () => {
    finishedSessionModal.current?.onOpen()
  }

  const onCloseFinishedModal = () => {
    finishedSessionModal.current?.onClose()
  }

  const onShowReport = useCallback(() => {
    ;(async () => {
      if (!reportCode) {
        navigate(`/report-channel?customerId=${report?.customerId}`)
        return
      }

      const loadingId = loading()

      try {
        const reportResponse: any = await listReport(reportCode)

        if (reportResponse.category.type === 'REPORT_FINISHED') {
          throw new Error('Report is finished')
        }

        const messagesResponse: any = await listMessages(reportResponse.id)

        setReport(reportResponse)

        const parseMessages = messagesResponse.map((msg: any) => ({
          sender: msg.isAdministrative ? 'thirdPerson' : 'firstPerson',
          content: msg.content
        }))

        setMessages([...initMessages(), ...parseMessages])
      } catch (err) {
        navigate(-1)

        error(translate('followReport.error'))
      } finally {
        loading(loadingId)
      }
    })()
  }, [
    error,
    initMessages,
    listMessages,
    listReport,
    loading,
    navigate,
    report?.customerId,
    reportCode,
    translate
  ])

  useEffect(() => {
    onShowReport()
  }, [onShowReport])

  const onClickFinishSession = () => {
    ;(async () => {
      const loadingId = loading()

      try {
        await finishReport(report!.id)

        onCloseFinishModal()

        onOpenFinishedModal()
      } catch (err) {
        error(translate('followReport.finishSession.error'))
      } finally {
        loading(loadingId)
      }
    })()
  }

  const finishSession = () => {
    onCloseFinishedModal()

    navigate(`/report-channel?customerId=${report?.customerId}`)
  }

  const onAddMessage = useCallback(
    (content: string) => {
      ;(async () => {
        setIsLoading(true)

        try {
          await createMessage({
            content,
            reportId: report!.id
          })

          setMessages([...messages, { content, sender: 'firstPerson' }])
        } catch (err) {
          error(translate('followReport.message.error'))
        } finally {
          setIsLoading(false)
        }
      })()
    },
    [createMessage, error, messages, report, translate]
  )

  return {
    translate,
    messages,
    onOpenFinishModal,
    onAddMessage,
    isLoading,
    onCloseFinishModal,
    onClickFinishSession,
    finishSessionModal,
    finishedSessionModal,
    finishSession
  }
}
