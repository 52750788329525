import { useCallback } from 'react'

import { customerRelationshipService } from 'services/customerRelationshipService'

import { IResponseProposalDTO } from 'dtos/IResponseProposalDTO'
import { IRequestValidateSubscriber } from 'dtos/IRequestValidateSubscriber'
import { IProposal } from 'interfaces/proposal'

import { useFormatting } from './useFormatting'

export const useRequestProposal = () => {
  const { parsePriceFloat } = useFormatting()

  const parseRequestProposal = useCallback(
    (proposal: IProposal) => {
      return {
        proposalProducts: proposal.products.map((productItem) => ({
          productId: productItem.item?.value,
          amount: productItem.quantity,
          installments: productItem.installments,
          value: parsePriceFloat(productItem.value),
          dependentsIds: []
        })),
        proposalSubscribers: proposal.subscribers
      }
    },
    [parsePriceFloat]
  )

  const createProposal = useCallback(
    (customerId: string, proposal: IProposal) => {
      return customerRelationshipService.post('proposals/create', {
        customerId,
        ...parseRequestProposal(proposal)
      })
    },
    [parseRequestProposal]
  )

  const listAllProposals = useCallback((query?: string) => {
    return customerRelationshipService.get(`proposals/list${query || ''}`)
  }, [])

  const deleteProposal = useCallback((id: string) => {
    return customerRelationshipService.patch(`proposals/inactivate/${id}`)
  }, [])

  const listProposal = useCallback((id: string) => {
    return customerRelationshipService.get(`proposals/listOneDetailed/${id}`)
  }, [])

  const listSubscriberProposal = useCallback(
    (id: string): Promise<IResponseProposalDTO> => {
      return customerRelationshipService.get(
        `proposals/listSubscriberProposal/${id}`
      )
    },
    []
  )

  const sendSubscriberValidationCode = useCallback((subscriberId: string) => {
    return customerRelationshipService.post(
      'proposals/sendSubscriberValidationCode',
      { subscriberId }
    )
  }, [])

  const validateSubscriberCode = useCallback(
    (subscriberRequest: IRequestValidateSubscriber) => {
      return customerRelationshipService.put(
        'proposals/validateSubscriberCode',
        subscriberRequest
      )
    },
    []
  )

  return {
    createProposal,
    listAllProposals,
    deleteProposal,
    listProposal,
    listSubscriberProposal,
    sendSubscriberValidationCode,
    validateSubscriberCode
  }
}
