import { useState } from 'react'

import { IInputOnChange } from 'components/Form'

export const usePageAuthenticationStep = () => {
  const [code, setCode] = useState<string>('')

  const onChange: IInputOnChange = (_name, value) => {
    setCode(value)
  }

  return { code, onChange }
}
