import React from 'react'

import { Form, Input, Item, Label, Select } from 'components/Form'
import { Button } from 'components/Button'
import { InnerHTML } from 'components/InnerHTML'

import { usePageReportDetails } from './usePageReportDetails'

import * as S from './styles'

export const ReportDetails: React.FC = () => {
  const {
    translate,
    reportData,
    renderSubtypeOptions,
    renderToWhoOptions,
    onChange,
    onSubmit,
    isSubmitLoading
  } = usePageReportDetails()

  return (
    <S.Wrapper>
      <Form onSubmit={onSubmit}>
        <Item>
          <Label>
            {translate(
              `report.new.reportStep.steps.reportDetails.form.subType.${reportData.type?.value}.label`
            )}
            <Select
              name="subType"
              value={reportData.subType}
              onChange={onChange}
              options={renderSubtypeOptions()}
              placeholder={translate(
                `report.new.reportStep.steps.reportDetails.form.subType.${reportData.type?.value}.placeholder`
              )}
              disabled={isSubmitLoading}
              required
            />
          </Label>
        </Item>

        <Item>
          <Label>
            {translate(
              `report.new.reportStep.steps.reportDetails.form.toWho.label`
            )}
            <Select
              name="toWho"
              value={reportData.toWho}
              onChange={onChange}
              options={renderToWhoOptions()}
              placeholder={translate(
                `report.new.reportStep.steps.reportDetails.form.toWho.placeholder`
              )}
              disabled={isSubmitLoading}
              required
            />
          </Label>
        </Item>

        <Item>
          <Input
            name="acceptTerms"
            type="checkbox"
            onChange={onChange}
            label={
              <InnerHTML
                type="span"
                text={translate(
                  'report.new.reportStep.steps.reportDetails.form.acceptTerms.label'
                )}
              />
            }
            disabled={isSubmitLoading}
            required
          />
        </Item>

        <S.ButtonWrapper>
          <Button loading={isSubmitLoading}>
            {translate(
              'report.new.reportStep.steps.reportDetails.form.submit.button'
            )}
          </Button>
        </S.ButtonWrapper>
      </Form>
    </S.Wrapper>
  )
}
