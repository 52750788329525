import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 1rem;
`

export const Chat = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 42rem;
  min-height: 43.5rem;
  margin: 0 auto;
  box-shadow: 0px 4px 4px var(--gray-50);
  background-color: var(--white);
`

export const ModalWrapper = styled.div`
  .modal-container {
    max-width: 38rem;
  }
`
