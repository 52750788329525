import React from 'react'

import reportChannelImg from 'assets/img/report-channel.png'

import { usePageStatusBarClient } from '../StatusBarClient/usePageStatusBarClient'

import * as S from './styles'

export const ReportLogos: React.FC = () => {
  const { translate, customerState } = usePageStatusBarClient()

  return (
    <S.Header>
      <img
        src={reportChannelImg}
        alt={translate('reportChannel.logos.alt.reportChannel')}
      />
      <S.LogosDivider />
      <h3>{customerState.name}</h3>
    </S.Header>
  )
}
