import { useNavigate, useLocation } from 'react-router-dom'

import { authService } from 'services/authService'

import { useToast } from 'hooks/useToast'
import { useTranslation } from 'hooks/useTranslation'
import { useLoading } from 'hooks/useLoading'

import { IEmailConfirmationResponse } from 'interfaces/authentication'

export const useEmailConfirmation = () => {
  const { error } = useToast()

  const { translate } = useTranslation()

  const { loading } = useLoading()

  const navigate = useNavigate()

  const location = useLocation()

  const getPasswordToken = async () => {
    const loadingId = loading()

    const urlParams = new URLSearchParams(location.search)
    const confirmationToken = urlParams.get('token')

    if (!confirmationToken) {
      loading(loadingId)
      navigate('/login')
      return
    }

    try {
      const response: IEmailConfirmationResponse = await authService.post('/auth/emailConfirmation', {
        token: confirmationToken
      })
      const resetPasswordToken = response.token

      loading(loadingId)

      navigate(`/reset-password?token=${resetPasswordToken}`)
    } catch (e) {
      error(translate('emailConfirmation.error'))

      loading(loadingId)

      navigate(`/login`)
    }
  }

  return {
    getPasswordToken
  }
}
